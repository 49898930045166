<template>
  <IndexView/>
  <section class="p-6">
    <BackButton class="mb-5" to_url="/pelanggan" />
    <PageTitle :title="isEdit ? 'Edit Pelanggan' : 'Tambah Pelanggan'"/>
    <div class="w-3/4 md:w-3/4 lg:w-1/2 bg-white p-6 rounded-md shadow-md">
      <!-- <div class="grid grid-cols-12 gap-4 mb-6">
        <div class="col-span-4">
          <InputText v-model="customer.firstName" input_class="w-full" label="Nama Depan" label_class="mb-1 block text-start" type="text" name="name" placeholder="Nama depan"/>
        </div>
        <div class="col-span-4">
          <InputText v-model="customer.middleName" input_class="w-full" label="Nama Tengah" label_class="mb-1 block text-start" type="text" name="name" placeholder="Nama depan"/>
        </div>
        <div class="col-span-4">
          <InputText v-model="customer.lastName" input_class="w-full" label="Nama Belakang" label_class="mb-1 block text-start" type="text" name="name" placeholder="Nama depan"/>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 mb-6">
        <div class="col-span-6">
          <InputText v-model="customer.email" input_class="w-full" label="Email" label_class="mb-1 text-color-1 block text-start" type="email" name="email" placeholder="example@mail.com"/>
        </div>
        <div class="col-span-6">
          <InputText v-model="customer.phone" input_class="w-full" label="No Telepon" label_class="mb-1 text-color-1 block text-start" type="text" name="number_phone" placeholder="Cth: 081234567890"/>
        </div>
      </div>
      <TextArea v-model="customer.address" label="Alamat Lengkap" textarea_class="mb-8" /> -->
      <InputText id="customer-firstname" v-model="customer.firstName" input_class="w-full mb-3" label="Nama Depan" label_class="mb-1 block text-start" type="text" placeholder="Nama depan"/>
      <InputText id="customer-middlename" v-model="customer.middleName" input_class="w-full mb-3" label="Nama Tengah" label_class="mb-1 block text-start" type="text" placeholder="Nama depan"/>
      <InputText id="customer-lastname" v-model="customer.lastName" input_class="w-full mb-3" label="Nama Belakang" label_class="mb-1 block text-start" type="text" placeholder="Nama akhir"/>
      <InputText id="customer-email" v-model="customer.email" input_class="w-full mb-3" label="Email" label_class="mb-1 text-color-1 block text-start" type="email" placeholder="example@mail.com"/>
      <InputText id="customer-phone" v-model="customer.phone" input_class="w-full mb-3" label="No Telepon" label_class="mb-1 text-color-1 block text-start" type="text" placeholder="Cth: 081234567890"/>

      <div class="flex justify-center">
        <SubmitButton id="submit" @click="submit" button_name="Simpan" button_class="mt-3 rounded-md bg-color-1 px-6 py-2 text-base font-semibold text-color-4 shadow-sm hover:bg-color-2 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-color-3 block"/>
      </div>
    </div>
  </section>
</template>

<script>
import IndexView from '@/views/IndexView.vue';
import PageTitle from '@/components/PageTitle.vue';
import BackButton from '@/components/buttons/BackButton.vue';
import InputText from '@/components/form/InputText.vue';
// import TextArea from '@/components/form/TextArea.vue';
import SubmitButton from '@/components/form/SubmitButton.vue';
import { mapActions } from 'vuex';

export default {
  name: "AddEditCustomer",
  props: ['id'],
  components: {
    IndexView, PageTitle, BackButton, InputText, SubmitButton
  },
  data() {
    return {
      customer: {
        id: null,
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        phone: '',
        address: ''
      },
      isEdit: false
    }
  },
  methods: {
    ...mapActions('customers', ['createCustomer', 'updateCustomer', 'fetchCustomerById']),
    async submit() {
      if(this.isEdit) {
        try {
          await this.updateCustomer(this.customer);
          alert('Data pelanggan berhasil diubah!');
          this.$router.push('/pelanggan');
        }
        catch (error) {
          console.error('Failed to update customer', error);
        }
      }
      else {
        try {
          await this.createCustomer(this.customer);
          alert('Data pelanggan berhasil ditambah!');
          this.$router.push('/pelanggan');
        }
        catch(error) {
          console.error('Failed to create customer', error);
        }
      }
    }
  },
  async created() {
    const customerId = this.id;

    if(customerId) {
      this.isEdit = true;

      try {
        const response = await this.fetchCustomerById(customerId);
        const customerData = response.data;

        this.customer = {
          id: customerData.id,
          firstName: customerData.firstName,
          middleName: customerData.middleName,
          lastName: customerData.lastName,
          email: customerData.email,
          phone: customerData.phone,
          address: customerData.address
        }
      }
      catch(error) {
        console.error('Failed to fetch customer', error);
      }
    }
  }
}
</script>