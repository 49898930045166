<template>
  <section class="w-screen h-screen absolute flex justify-center items-center z-50">
    <div id="modal modal-customer" class="modal absolute block w-5/12 bg-white shadow-md rounded-lg"
        :class="{
        'animate__animated animate__zoomIn': showModal,
        'animate__animated animate__zoomOut': !showModal,
        }">
        <div id="modal-header" class="w-full bg-color-1 py-3 rounded-t-lg">
          <h5 class="text-white text-center font-bold text-xl">{{ isEdit ? 'Edit Atribut' : 'Tambah Atribut' }}</h5>
        </div>
        <div id="modal-body" class="p-5">
          <InputText v-model="attributeName" id="attribute-name" label="Nama Atribut" input_class="w-full"/>
        </div>
        <div class="modal-footer p-5">
          <div class="flex justify-center">
            <SubmitButton id="close" button_name="Close" button_class="mx-1 text-red-600 border-red-600 hover:text-white hover:bg-red-600" @click="closeModal"/>
            <SubmitButton id="submit" button_name="Submit" button_class="mx-1 bg-color-1 hover:bg-color-2 text-color-4" @click="submitAttribute"/>
          </div>
        </div>
    </div>
  </section>
</template>

<script>
import InputText from '../form/InputText.vue';
import SubmitButton from '../form/SubmitButton.vue';
import { mapActions } from 'vuex';

export default {
  name: "ModalAttribute",
  props: {
    attribute: {
      type: Object,
      default: null,
    }
  },
  components: {
    InputText, SubmitButton
  },
  data() {
    return {
      showModal: true,
      attributeName: this.attribute ? this.attribute.name : '',
      isEdit: !!this.attribute
    };
  },
  watch: {
    attribute(newValue) {
      if (newValue) {
        this.attributeName = newValue.name;
        this.isEdit = true;
      } else {
        this.attributeName = '';
        this.isEdit = false;
      }
    }
  },
  methods: {
    ...mapActions('attributes', ['createAttribute', 'updateAttribute']),
    closeModal() {
      this.showModal = false;
      this.$emit('closeModal');
      setTimeout(() => {
        this.$emit('closeModal');
      }, 200); 
    },
    async submitAttribute() {
      try {
        const newAttribute = {
          name: this.attributeName
        };
        
        if (this.isEdit && this.attribute) {
          newAttribute.id = this.attribute.id;
          await this.updateAttribute(newAttribute);
        } else {
          await this.createAttribute(newAttribute);
        }
        
        this.closeModal();
      }
      catch (error) {
        console.error('Failed to save attribute: ', error);
      }
    }
  }
}
</script>