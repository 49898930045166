<template>
  <IndexView/>
  <section class="p-6">
    <PageTitle title="Outlet" />
    <button class="mb-10">
      <AddButton id="add-data" to_url="/outlet/tambah-outlet" button_text="+ Tambah Outlet" button_class="py-2 font-medium text-base"/>
    </button>
    <div class="grid gap-6 grid-cols-3 md:grid-cols-2 lg:grid-cols-4">
      <OutletCard v-for="outlet in allOutlets" :key="outlet.id"
        :outlet_id="outlet.id"
        :outlet_name="outlet.name"
        :outlet_address="outlet.address"
        :outlet_phone="outlet.phone_number"
        :edit_data="'edit-data-' + outlet.id"
        :delete_data="'delete-data-' + outlet.id"
      />
    </div>
  </section>
</template>

<script>
import IndexView from '@/views/IndexView.vue';
import PageTitle from '@/components/PageTitle.vue';
import AddButton from '@/components/buttons/AddButton.vue';
import OutletCard from '@/components/OutletCard.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "OutletView",
  components: {
    IndexView, PageTitle, OutletCard, AddButton
  },
  computed: {
    ...mapGetters('outlets', ['allOutlets'])
  },
  created() {
    this.$store.dispatch('outlets/fetchOutlets');
  },
  methods: {
    ...mapActions('outlets', ['deleteOutletById']),
    async deleteOutlet(outlet) {
      if (confirm(`Yakin ingin menghapus outlet ${outlet.name}?`)) {
        try {
          await this.deleteOutletById(outlet.id);
          alert('Outlet berhasil dihapus');
          this.$store.dispatch('outlets/fetchOutlets');
        }
        catch (error) {
          console.error('Gagal menghapus outlet', error);
          alert('Gagal menghapus outlet');
        }
      }
    }
  }
}
</script>