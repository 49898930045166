<template>
    <section class="mb-[70px]">
        <SideBar ref="sidebar" @toggleBlur="toggleBlur"/>
        <TopBar @toggleBlur="toggleBlur"/>

        <div
            id="overlay"
            class="fixed inset-0 bg-black bg-opacity-30 z-30"
            :class="{ 'hidden': !isBlur }"
        ></div>
        <!-- <ElementOverlay :overlay_class="{ 'hidden': !isBlur }"/> -->
    </section>
</template>

<script>
import TopBar from '@/components/TopBar.vue';
import SideBar from '@/components/SideBar.vue';
// import ElementOverlay from '@/components/elements/ElementOverlay.vue';

export default {
    name: 'IndexView',
    components: { TopBar, SideBar },
    data() {
        return {
            isBlur: false
        }
    },
    methods: {
        toggleBlur() {
            this.isBlur = !this.isBlur
        }
    }
}
</script>

<style>
#overlay {
    backdrop-filter: blur(5px);
}
</style>