<template>
    <div
      id="overlay"
      class="fixed inset-0 bg-black bg-opacity-30 z-30"
      :class="{ 'hidden': !isBlur }"
    ></div>
    <section class="bg-gradient-to-r from-color-1 to-color-5 px-6 py-4 flex justify-between items-center w-full z-40 h-[70px] fixed">
        <div class="flex items-center">
            <div id="open-sidebar" class="flex flex-col w-8 h-5 justify-between cursor-pointer me-5" @click="toggleSidebar">
                <span id="hamburger_item" class="bg-color-4 rounded-full"></span>
                <span id="hamburger_item" class="bg-color-4 rounded-full"></span>
                <span id="hamburger_item" class="bg-color-4 rounded-full"></span>
            </div>
            <div>
                <RouterLink to="/dashboard" class="text-color-1 bg-color-4 px-3 py-1 rounded-md flex">
                    <HomeIcon class="h-5 w-5 text-color-1 me-2"/> Beranda
                </RouterLink>
            </div>
        </div>
        <div class="profile relative">
            <span class="flex items-center">
                <div id="profile-button" @click="toggleProfileDropdown" class="cursor-pointer">
                    <UserCircleIcon class="h-10 w-10 text-color-4"/>
                </div>
            </span>
            <div v-if="isProfileDropdownOpen" class="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg z-50 animate__animated" :class="{'animate__zoomIn': isProfileDropdownOpen, 'animate__animated animate__fadeOut': !isProfileDropdownOpen}">
                <RouterLink id="url-profile" to="/profil" class=" px-4 py-2 text-sm flex hover:bg-gray-100">
                    <UserIcon class="me-2 h-4 w-4"/> Profil
                </RouterLink>
                <button id="logout" @click="logout" class="w-full flex text-left px-4 py-2 text-sm hover:bg-gray-100">
                    <ArrowRightOnRectangleIcon class="h-4 w-4 me-2"/> Logout
                </button>
            </div>
        </div>
    </section>
</template>

<script>
import { UserCircleIcon, UserIcon, ArrowRightOnRectangleIcon, HomeIcon } from '@heroicons/vue/24/solid';

export default {
    name: 'TopBar',
    components: {
        UserCircleIcon, UserIcon, ArrowRightOnRectangleIcon, HomeIcon
    },
    data() {
        return {
            modalAttendance: false,
            isBlur: false,
            isProfileDropdownOpen: false,
        }
    },
    methods: {
        toggleSidebar() {
            this.$parent.$refs.sidebar.toggleSidebar();
            this.$emit('toggleBlur');
        },
        toggleProfileDropdown() {
            this.isProfileDropdownOpen = !this.isProfileDropdownOpen;
            if (this.isProfileDropdownOpen) {
                document.addEventListener('click', this.closeProfileDropdown);
            }
        },
        closeProfileDropdown(event) {
            if (event && this.$refs.profileDropdown && !this.$refs.profileDropdown.contains(event.target)) {
                this.isProfileDropdownOpen = false;
                document.removeEventListener('click', this.closeProfileDropdown);
            }
        },
        logout() {
            localStorage.clear();
            this.$router.push('/');
        }
    }
}
</script>

<style>
 #hamburger_item {
    height: 3px;
 }
</style>