<template>
  <aside class="z-[99999] shadow-xl h-full absolute bg-white w-72 transition-all duration-300 ease-in-out" :class="[isSidebarOpen ? 'translate-x-0' : '-translate-x-full']">
    <div id="sidebar_top" class="bg-gradient-to-r from-color-1 to-color-5 px-4 py-6 flex justify-between">
        <h3 class="text-xl font-medium text-color-4">Erista POS</h3>
        <span id="close-sidebar" class="cursor-pointer" @click="closeSidebar">
            <ArrowLeftIcon class="h-6 w-6 text-color-4 text-bold" />
        </span>
    </div>
    <div id="sidebar_main" class="flex flex-col items-start px-4 py-7">
        <template v-for="item in menuItems" :key="item.path">
            <RouterLink :to="item.path" :id="item.id" class="mb-4 flex text-color-1 hover:text-color-5">
                <component :is="item.icon" class="h-5 w-5 me-2 text-bold" />
                {{ item.name }}
            </RouterLink>
        </template>
        <!-- <span class="mb-4 flex">
            <ClipboardDocumentListIcon class="h-5 w-5 me-2 text-bold" />
            Laporan
        </span> -->
    </div>
  </aside>
</template>

<script>
import { ArrowLeftIcon, HomeIcon, ClipboardDocumentListIcon, CubeIcon, ClockIcon, CogIcon, ClipboardDocumentCheckIcon, UserGroupIcon, IdentificationIcon, BuildingLibraryIcon, DocumentTextIcon, ServerStackIcon } from '@heroicons/vue/24/solid';

export default {
    name: 'SideBar',
    components: { ArrowLeftIcon, HomeIcon, ClipboardDocumentListIcon, CubeIcon, ClockIcon, CogIcon, ClipboardDocumentCheckIcon, UserGroupIcon, IdentificationIcon, BuildingLibraryIcon, DocumentTextIcon, ServerStackIcon },
    data() {
        return {
            isSidebarOpen: false,
            menuItems: [
                { path: '/dashboard', name: 'Beranda', id: 'url-dashboard', icon: HomeIcon, permission: ['1', '2'] },
                { path: '/absensi', name: 'Absensi', id: 'url-absensi', icon: ClipboardDocumentCheckIcon, permission: ['1', '2'] },
                { path: '/pegawai', name: 'Pegawai', id: 'url-pegawai', icon: IdentificationIcon, permission: ['1'] },
                { path: '/pelanggan', name: 'Pelanggan', id: 'url-pelanggan', icon: UserGroupIcon, permission: ['1', '2'] },
                { path: '/outlet', name: 'Outlet', id: 'url-outlet', icon: BuildingLibraryIcon, permission: ['1'] },
                { path: '/inventori', name: 'Inventori', id: 'url-inventori', icon: ServerStackIcon, permission: ['1'] },
                { path: '/atribut', name: 'Atribut', id: 'url-atribut', icon: DocumentTextIcon, permission: ['1'] },
                { path: '/atribut-list', name: 'Atribut List', id: 'url-atribut-list', icon: DocumentTextIcon, permission: ['1'] },
                { path: '/kategori', name: 'Kategori', id: 'url-kategori', icon: DocumentTextIcon, permission: ['1'] },
                { path: '/kelola-produk', name: 'Kelola Produk', id: 'url-kelola-produk', icon: CubeIcon, permission: [ '1'] },
                { path: '/riwayat-transaksi', name: 'Riwayat Transaksi', id: 'url-riwayat-transaksi', icon: ClockIcon, permission: ['1'] },
                { path: '/pengaturan', name: 'Pengaturan', id: 'url-pengaturan', icon: CogIcon, permission: ['1', '2'] },
            ]
        }
    },
    computed: {
        filteredMenuItems() {
            const permission = localStorage.getItem('user_permission');
            const items = this.menuItems.filter(item => item.permission.includes(permission));
            return items;
        }
    },
    methods: {
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
        closeSidebar() {
            this.isSidebarOpen = false;
            this.$emit('toggleBlur');
        }
    }
}
</script>