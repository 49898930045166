<template>
  <div :id="id" class="tab-content w-full bg-white shadow-md rounded-lg p-6 mt-6">
    <div class="flex">
      <h2 class="text-xl font-medium">Pengaturan 2</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pengaturan_2",
  props:{
    id: String
  }
}
</script>