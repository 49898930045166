import { createRouter, createWebHistory } from "vue-router";
import AuthView from "@/views/AuthView.vue";
import IndexProfileView from "@/views/pages/profile/ProfileView.vue";
import DashboardView from "@/views/pages/DashboardView.vue";
import OrderListView from "@/views/pages/OrderListView.vue";
import AttendanceView from "@/views/pages/attendance/AttendanceView.vue";
import AttendanceDetailView from "@/views/pages/attendance/AttendanceDetailView.vue";
import EmployeeView from "@/views/pages/employee/EmployeeView.vue";
import AddEditEmployee from "@/views/pages/employee/AddEditEmployee.vue";
import CustomerView from "@/views/pages/customer/CustomerView.vue";
import AddEditCustomer from "@/views/pages/customer/AddEditCustomer.vue";
import OutletView from "@/views/pages/outlet/OutletView.vue";
import AddEditOutlet from "@/views/pages/outlet/AddEditOutlet.vue";
import ProductManagementView from "@/views/pages/product/ProductManagementView.vue";
import AddEditProduct from "@/views/pages/product/AddEditProduct.vue";
import InventoryView from "@/views/pages/inventory/InventoryView.vue";
import AddEditInventory from "@/views/pages/inventory/AddEditInventory.vue";
import AttributeView from "@/views/pages/attribute/AttributeView.vue";
import AttributeListView from "@/views/pages/attribute/AttributeListView.vue";
import CategoryView from "@/views/pages/category/CategoryView.vue";
import SettingView from "@/views/pages/setting/SettingView.vue";
import TransactionView from "@/views/pages/transaction/TransactionView.vue";
import CheckoutView from "@/views/pages/CheckoutView.vue";

import { requireAuth } from '@/middleware/auth';
// import { requireSuperadmin } from '@/middleware/role';

const routes = 
[
    { path:'/', component: AuthView },
    { path:'/profil', component: IndexProfileView, beforeEnter: requireAuth },
    {
        path:'/dashboard',
        name:'Dashboard',
        component: DashboardView,
        beforeEnter: requireAuth
    },
    {
        path:'/dashboard/transaksi/:id',
        name:'EditTransaction',
        props: true,
        component: DashboardView,
        beforeEnter: requireAuth
    },
    {
        path:'/order_list',
        component: OrderListView,
        beforeEnter: requireAuth
    },
    {
        path:'/checkout/transaksi/:id',
        name:'CheckoutView',
        props: true,
        component: CheckoutView,
        beforeEnter: requireAuth
    },
    {
        path:'/absensi',
        component: AttendanceView,
        beforeEnter: requireAuth
    },
    {
        path:'/absensi/detail',
        component: AttendanceDetailView,
        beforeEnter: requireAuth
    },
    {
        path:'/pegawai',
        name: 'EmployeeView',
        component: EmployeeView,
        beforeEnter: requireAuth
    },
    { path:'/pegawai/tambah-pegawai', name: 'AddEmployee', component: AddEditEmployee, beforeEnter: requireAuth },
    { path:'/pegawai/edit-pegawai/:id', name: 'EditEmployee', component: AddEditEmployee, props:true, beforeEnter: requireAuth },
    { path:'/pelanggan', name: 'CustomerView', component: CustomerView, beforeEnter: requireAuth },
    { path:'/pelanggan/tambah-pelanggan', name: 'AddCustomer', component: AddEditCustomer, beforeEnter: requireAuth },
    { path:'/pelanggan/edit-pelanggan/:id', name: 'EditCustomer', component: AddEditCustomer, props:true, beforeEnter: requireAuth },
    { path:'/outlet', component: OutletView, beforeEnter: requireAuth },
    { path:'/outlet/tambah-outlet', name:'AddOutlet', component: AddEditOutlet, beforeEnter: requireAuth },
    { path:'/outlet/edit-outlet/:id', name:'EditOutlet', component: AddEditOutlet, props:true, beforeEnter: requireAuth },
    { path:'/inventori', component: InventoryView, beforeEnter: requireAuth },
    { path:'/inventori/tambah-inventori', name:'AddInventory', component: AddEditInventory, beforeEnter: requireAuth },
    { path:'/inventori/edit-inventori/:id', name:'EditInventory', component: AddEditInventory, props:true,  beforeEnter: requireAuth },
    { path:'/atribut', component: AttributeView, beforeEnter: requireAuth },
    { path:'/atribut-list', component: AttributeListView, beforeEnter: requireAuth },
    { path:'/kategori', component: CategoryView, beforeEnter: requireAuth },
    { path:'/kelola-produk', name: 'ProductManagementView', component: ProductManagementView, beforeEnter: requireAuth },
    { path:'/kelola-produk/tambah-produk', name: 'AddProduct', component: AddEditProduct, beforeEnter: requireAuth },
    { path:'/kelola-produk/edit-produk/:id', name: 'EditProduct', component: AddEditProduct, props:true, beforeEnter: requireAuth },
    { path:'/riwayat-transaksi', component: TransactionView, beforeEnter: requireAuth },
    { path:'/pengaturan', component: SettingView, beforeEnter: requireAuth },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;