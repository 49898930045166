<template>
  <ModalPaymentMethod
    v-if="modalShowPaymentMethod"
    @closeModal="closeModal"
    :paymentMethod="selectedPaymentMethod"/>
  <div
    id="overlay"
    class="fixed inset-0 bg-black bg-opacity-30 z-30"
    :class="{ 'hidden': !isBlur }"
  ></div>
  <div :id="id" class="tab-content w-1/2 md:w-3/4 bg-white shadow-md rounded-lg p-6 mt-6">
    <div class="flex mb-8 justify-between">
      <h2 class="text-xl font-medium me-3">Metode Pembayaran</h2>
      <AddButton to_url="#" button_text="+ Tambah Metode Pembayaran" button_class="text-sm py-1" @click="showModal"/>
    </div>
    <div>
      <table class="table-auto w-full">
        <thead class="bg-color-1">
          <tr>
            <th class="p-3 border-b text-color-4 text-left">No</th>
            <th class="p-3 border-b text-color-4 text-left">Metode Pembayaran</th>
            <th class="p-3 border-b text-color-4 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="allPaymentMethods.length == 0">
            <td colspan="3" class="p-3 text-center italic text-gray-400">Belum ada data</td>
          </tr>
          <tr v-else v-for="(paymentMethod, index) in allPaymentMethods" :key="paymentMethod.id" :class="{'bg-blue-50' : index % 2 == 1}" class="hover:bg-blue-100">
            <td class="p-3 text-gray-500">{{ index + 1 }}</td>
            <td class="p-3 text-gray-500">{{ paymentMethod.description }}</td>
            <td class="p-3 text-gray-500 flex">
              <button :id="'edit-data-' + paymentMethod.id" @click="editPaymentMethod(paymentMethod)" class="border border-gray-400 hover:border-green-700 hover:bg-green-400 flex justify-center items-center mx-1 p-2 rounded-md group">
                <PencilSquareIcon class="h-4 w-4 group-hover:text-green-700"/>
              </button>
              <button :id="'delete-data-' + paymentMethod.id" @click="deletePaymentMethod(paymentMethod)" class="border border-gray-400 hover:border-red-500 hover:bg-red-400 flex justify-center items-center mx-1 p-2 rounded-md group">
                <TrashIcon class="h-4 w-4 text-gray-500 group-hover:text-red-700"/>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ModalPaymentMethod from '@/components/modals/ModalPaymentMethod.vue';
import AddButton from '@/components/buttons/AddButton.vue';
import { mapGetters, mapActions } from 'vuex';
import { TrashIcon, PencilSquareIcon } from '@heroicons/vue/24/solid';

export default {
  name: "MetodePembayaranTab",
  components: {
    AddButton, ModalPaymentMethod,
    TrashIcon, PencilSquareIcon
  },
  data() {
    return {
      modalShowPaymentMethod: false,
      isBlur: false,
      selectedPaymentMethod: null
    }
  },
  props:{
    id: String
  },
  computed: {
    ...mapGetters('payment_methods', ['allPaymentMethods'])
  },
  created() {
    this.$store.dispatch('payment_methods/fetchPaymentMethods');
  },
  methods: {
    ...mapActions('payment_methods', ['deletePaymentMethodById']),
    showModal() {
      this.modalShowPaymentMethod = true;
      this.isBlur = true;
      this.selectedPaymentMethod = null;
    },
    closeModal() {
      setTimeout(() => {
        this.modalShowPaymentMethod = false;
        this.isBlur = false;
        this.$store.dispatch('payment_methods/fetchPaymentMethods');
      }, 200);
    },
    editPaymentMethod(paymentMethod) {
      this.selectedPaymentMethod = paymentMethod;
      this.modalShowPaymentMethod = true;
      this.isBlur = true;
    }, 
    async deletePaymentMethod(paymentMethod) {
      if (confirm(`Yakin ingin menghapus metode pembayaran "${paymentMethod.description}"?`)) {
        try {
          await this.deletePaymentMethodById(paymentMethod.id);
          alert('Metode pembayaran berhasil dihapus');
          this.$store.dispatch('payment_methods/fetchPaymentMethods');
        }
        catch (error) {
          console.error('Gagal menghapus metode pembayaran', error);
          alert('Gagal menghapus metode pembayaran');
        }
      }
    }
  },

}
</script>