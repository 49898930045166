<template>
  <button :id="id" type="submit" class="block border shadow-sm rounded-md px-5 font-medium py-2" :class="button_class">{{ button_name }}</button>
</template>

<script>
export default {
  name: 'SubmitButton',
  props: {
    id: String,
    button_class: String,
    button_name: String
  }
}
</script>