<template>
  <IndexView />
  <section class="p-6">
    <PageTitle title="Checkout" />
    <div class="grid grid-cols-12 gap-10">
      <div class="col-span-4 md:col-span-6">
        <div class="py-6 px-5 shadow-lg rounded-lg bg-white mb-3">
          <div class="mb-3">
            <div class="flex justify-between">
              <h4 class="text-gray-500">Nama Pelanggan: </h4>
              <p class="text-gray-500 text-sm">Kode transaksi: {{ transaction?.transaction_code }}</p>
            </div>
            <h3 class="font-bold text-2xl capitalize">{{ transaction?.customer?.firstName ? transaction?.customer?.firstName + ' ' +  transaction?.customer?.middleName : 'No name'}}</h3>
          </div>
          <hr class="border-b mb-5">
          <div class="mb-4">
            <h4 class="text-gray-500">Keterangan :</h4>
            <h3 class="font-semibold">{{ transaction?.customer_location || '---'}}</h3>
          </div>
          <div class="mb-4">
            <h4 class="text-gray-500">Catatan :</h4>
            <h3 class="font-semibold">{{ transaction?.transaction_note || '---'}}</h3>
          </div>
        </div>
        <div class="py-6 px-5 shadow-lg rounded-lg bg-white mb-3">
          <table v-if="orderItems !== null" class="w-full mb-5">
            <tbody>
              <tr v-for="(item, index) in orderItems" :key="item.id" class="border-b">
                <td class="pb-2 pt-4">{{ index + 1}}</td>
                <td class="pb-2 pt-4 flex flex-col">
                  <h5 class="font-bold text-lg">{{ item.inventory_detail.Product.name }}</h5>
                  <h5 class="font-bold">
                    <span class="text-gray-400 font-light"> ({{ formatRupiah(item.price_per_unit) }} x {{ item.quantity }})</span>
                  </h5>
                </td>
                <td class="pb-2 pt-4 font-bold text-blue-600">{{ formatRupiah(item.subtotal) }}</td>
              </tr>
              <tr class="border-b">
                <td class="pb-2"></td>
                <td class="pb-2 pt-4 border-b"><h4 class="text-gray-600">Diskon :</h4></td>
                <td class="pb-2 pt-4 border-b"><h3 class="font-semibold text-blue-600">{{ transaction?.discount ? transaction?.discount + '%' : '---'}} </h3></td>
              </tr>
              <tr>
                <td></td>
                <td class="pt-4">Service charge :</td>
                <td class="pt-4">{{ formatRupiah(transaction?.service_charge) }}</td>
              </tr>
              <tr>
                <td></td>
                <td class="pt-4">Tax :</td>
                <td class="pt-4">{{ formatRupiah(transaction?.tax) }}</td>
              </tr>
            </tbody>
          </table>
          <div class="flex items-center justify-between p-4 text-white rounded-md bg-gradient-to-l from-color-1 to-color-5 font-bold text-xl">
            <h3>Grand Total</h3>
            <h3 class="flex flex-col">
              {{ formatRupiah(transaction?.grand_total) }}
            </h3>
          </div>
        </div>
        <div class="py-6 px-5 shadow-lg rounded-lg bg-white mb-3">
          <div class="flex flex-col mb-5">
            <label class="font-medium mb-1">Pilih Metode Pembayaran</label>
            <select v-model="pos_payment.payment_method_id" id="payment-method" class="w-full border border-gray-400 rounded-md px-3 py-2 text-gray-600">
              <option value="">Pilih metode pembayaran</option>
              <option v-for="paymentMethod in allPaymentMethods" :key="paymentMethod.id" :value="paymentMethod.id">{{ paymentMethod.description }}</option>
            </select>
          </div>
          <InputText v-model="pos_payment.total_payment" id="total_payment" label="Jumlah pembayaran" type="number" placeholder="Cth: 250000" input_class="w-full mb-4"/>
          <div class="mb-5">
            <label class="font-medium">Kembalian</label>
            <div class="font-semibold text-lg text-color-1">{{ formatRupiah(pos_payment.total_change) }}</div>
          </div>
          <div v-if="notification" class="bg-red-600 text-white text-center font-bold text-2xl mb-5 py-3 rounded-md">
            {{ notification }}
          </div>
          <SubmitButton @click="makePayment()" id="make-payment" button_name="Bayar" button_class="bg-color-1 text-white w-full py-5 font-bold text-3xl uppercase bg-gradient-to-l from-color-1 to-color-5" />
        </div>
      </div>
      <div class="col-span-4 md:col-span-6">
        <div v-if="invoice" class="w-full bg-white py-10 px-6 rounded shadow-md">
          <h1 class="text-center font-bold text-xl">INVOICE</h1>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IndexView from '@/views/IndexView.vue';
import PageTitle from '@/components/PageTitle.vue';
import InputText from '@/components/form/InputText.vue';
import SubmitButton from '@/components/form/SubmitButton.vue';
import { formatRupiah } from '@/helpers/formatRupiah';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CheckoutView',
  props: ['id'],
  components: {
    IndexView, PageTitle, InputText, SubmitButton
  },
  data() {
    return {
      transaction: '',
      orderItems: null,
      serviceItems: null,
      notification: '',
      invoice: false,
      pos_payment: {
        transaction_id: null,
        payment_method_id: '',
        total_payment: 0,
        total_change: 0,
      }
    }
  },
  methods: {
    ...mapActions('transactions', ['fetchTransactionById']),
    ...mapActions('pos_payments', ['createPosPayment']),
    formatRupiah,

    async makePayment() {
      const subtotal = this.transaction.subtotal;
      const total_change = parseInt(this.pos_payment.total_payment) - parseInt(subtotal);
      
      console.log("total payment: ", this.pos_payment.total_payment);
      console.log("subtotal: ", subtotal);
      console.log("total change: ", total_change);

      if(this.pos_payment.payment_method_id == '') {
        this.notification = 'Pilih metode pembayaran dulu!'
        setTimeout(() => {
          this.notification = '';
        }, 2000);
        return;
      }
      if(total_change <= 0) {
        console.log("total change: ", total_change);
        this.notification = 'Pembayaran Anda kurang!'

        setTimeout(() => {
          this.notification = '';
        }, 2000);

        return;
      }

      this.pos_payment.transaction_id = this.transaction.id;
      this.pos_payment.total_change = total_change;
      
      console.log('Transaksi: ', this.transaction);
      console.log('Make Payment: ', this.pos_payment);

      await this.createPosPayment(this.pos_payment);
      this.invoice = true;
      alert('Pembayaran berhasil!');
    }
  },
  computed: {
    ...mapGetters('payment_methods', ['allPaymentMethods']),
  },
  async created() {
    this.$store.dispatch('payment_methods/fetchPaymentMethods');

    const fetchTransaction = await this.fetchTransactionById(this.id)
    const transaction = fetchTransaction.data;
    const orderItems = transaction.order_items;
    const serviceItems = transaction.service_items;

    this.transaction = transaction;
    this.orderItems = orderItems;
    this.serviceItems = serviceItems;

    console.log('This Transaction: ', this.transaction);
  },
}
</script>