<template>
  <IndexView />
  <section class="p-6">
    <BackButton class="mb-5" to_url="/outlet" />
    <PageTitle :title="isEdit ? 'Edit Outlet' : 'Tambah Outlet'"/>
    <div class="w-1/2 bg-white shadow-md rounded-md p-6">
      <InputText id="outlet-name" v-model="name" input_class="w-full mb-6" label="Nama Cabang" label_class="mb-1 block text-start" type="text"/>
      <InputText id="outlet-phone-number" v-model="phone_number" input_class="w-full mb-6" label="No Telepon" label_class="mb-1 block text-start" type="text"/>
      <TextArea id="outlet-address" v-model="address" label="Alamat Lengkap" textarea_class="mb-8"/>
      <div class="flex justify-center">
        <SubmitButton id="submit" @click="submitOutlet" button_name="Simpan" button_class="rounded-md bg-color-1 px-6 py-2 text-base font-semibold text-color-4 shadow-sm hover:bg-color-2 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-color-3 block"/>
      </div>
    </div>
  </section>
</template>

<script>
import IndexView from '@/views/IndexView.vue';
import PageTitle from '@/components/PageTitle.vue';
import BackButton from '@/components/buttons/BackButton.vue';
import InputText from '@/components/form/InputText.vue';
import TextArea from '@/components/form/TextArea.vue';
import SubmitButton from '@/components/form/SubmitButton.vue';
import { mapActions } from 'vuex';

export default {
  name: "AddEditOutlet",
  components: {
    IndexView, PageTitle, BackButton, InputText, TextArea, SubmitButton
  },
  data() {
    return {
      id: null,
      name: '',
      address: '',
      phone_number: '',
      isEdit: false
    }
  },
  methods: {
    ...mapActions('outlets', ['createOutlet', 'updateOutlet', 'fetchOutletById']),
    async submitOutlet() {
      try {
        const outletData = {
          id: this.id,
          name: this.name,
          address: this.address,
          phone_number: this.phone_number
        };

        if (this.isEdit) {
          await this.updateOutlet(outletData);
          alert('Outlet Berhasil Diperbarui', outletData);
        } else {
          await this.createOutlet(outletData);
          alert('Outlet Berhasil Ditambah', outletData);
        }
        this.$router.push('/outlet');
      }
      catch (error) {
        console.error('Failed to create or update outlet', error);
      }
    },
    async loadOutlet() {
      if (this.isEdit) {
        try {
          const outlet = await this.fetchOutletById(this.id);
          this.name = outlet.name;
          this.address = outlet.address;
          this.phone_number = outlet.phone_number;
        } catch (error) {
          console.error('Failed to load outlet data', error);
        }
      }
    }
  },
  created() {
    const outletId = this.$route.params.id;
    if (outletId) {
      this.isEdit = true;
      this.id = outletId;
      this.loadOutlet();
    }
  }
}
</script>