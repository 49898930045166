<template>
  <IndexView />
  <section class="p-6">
    <BackButton class="mb-5" to_url="/pegawai" />
    <PageTitle :title="isEdit ? 'Edit Pegawai' : 'Tambah Pegawai'" />
    <div class="w-3/4 md:w-3/4 lg:w-1/2 bg-white p-6 rounded-md shadow-md">
      <div class="grid grid-cols-12 gap-4 mb-6">
        <div class="col-span-6">
          <InputText id="employee-name" v-model="employee.name" input_class="w-full" label="Nama Lengkap" label_class="mb-1 block text-start" type="text" placeholder="Nama depan"/>
        </div>
        <div class="col-span-6">
          <InputText id="employee-email" v-model="employee.email" input_class="w-full" label="Email" label_class="mb-1 block text-start" type="email" placeholder="example@mail.com"/>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 mb-6">
        <div class="col-span-6">
          <InputText id="employee-phone-number" v-model="employee.phone_number" input_class="w-full" label="No Telepon" label_class="mb-1 block text-start" type="text" placeholder="Cth: 081234567890"/>
        </div>
        <div class="col-span-6">
          <label class="mb-1 font-medium block">Role</label>
          <select id="employee-role-id" v-model="employee.role_id" class="w-full border border-gray-400 rounded-md px-3 py-2 text-gray-500 text-sm">
            <option value="">Pilih role</option>
            <option v-for="role in allRoles" :key="role.id" :value="role.id">{{ role.name }}</option>
          </select>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 mb-6">
        <div class="col-span-6">
          <label class="mb-1 font-medium block">Outlet</label>
          <select id="employee-outlet-id" v-model="employee.outlet_id" class="w-full border border-gray-400 rounded-md px-3 py-2 text-gray-500 text-sm">
            <option value="">Pilih outlet</option>
            <option v-for="outlet in allOutlets" :key="outlet.id" :value="outlet.id">{{ outlet.name }}</option>
          </select>
        </div>
      </div>
      <TextArea id="employee-address" v-model="employee.address" label="Alamat Lengkap" textarea_class="mb-6" name="address" />
      <!-- <div class="grid grid-cols-12 gap-6 mb-6" v-if="isEdit">
        <div class="col-span-6">
          <InputPassword id="employee-current-password" v-model="employee.current_password" label="Current Password" label_class="mb-1 block text-start" placeholder="Current Password"/>
        </div>
      </div> -->
      <div class="grid grid-cols-12 gap-6 mb-10">
        <div class="col-span-6">
          <InputPassword id="employee-new-password" v-model="employee.password" label="Password" label_class="mb-1 block text-start" placeholder="Password"/>
        </div>
        <div class="col-span-6">
          <InputPassword id="password-confirmation" v-model="employee.confirmation" label="Konfirmasi Password" label_class="mb-1 block text-start" placeholder="Konfirmasi Password"/>
        </div>
      </div>
      <div class="flex justify-center">
        <SubmitButton id="submit" @click="submitEmployee" button_name="Simpan" button_class="rounded-md bg-color-1 px-6 py-2 text-base font-semibold text-color-4 shadow-sm hover:bg-color-2 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-color-3 block"/>
      </div>
    </div>
  </section>
</template>

<script>
import IndexView from '@/views/IndexView.vue';
import PageTitle from '@/components/PageTitle.vue';
import TextArea from '@/components/form/TextArea.vue';
import InputText from '@/components/form/InputText.vue';
import InputPassword from '@/components/form/InputPassword.vue';
import BackButton from '@/components/buttons/BackButton.vue';
import SubmitButton from '@/components/form/SubmitButton.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "AddEditEmployeeView",
  props: ['id'],
  components: {
    IndexView, PageTitle, TextArea, InputText, InputPassword, BackButton, SubmitButton
  },
  data() {
    return {
      employee: {
        id: null,
        name: '',
        email: '',
        role_id: '',
        outlet_id: '',
        phone_number: '',
        address: '',
        password: '',
        confirmation: '',
        // current_password: '',
      },
      isEdit: false,
      originalPassword: ''
    };
  },
  computed: {
    ...mapGetters('outlets', ['allOutlets']),
    ...mapGetters('roles', ['allRoles']),
  },
  methods: {
    ...mapActions('employees', ['createEmployee', 'updateEmployee', 'fetchEmployeeById']),
    async submitEmployee() {
      if(this.employee.password !== this.employee.confirmation) {
        alert('Konfirmasi password tidak sesuai!');
        return;
      } 
      if (this.isEdit) {
        try {
          if(this.employee.password && this.employee.confirmation) {
            const token = localStorage.getItem('auth_token');
            const updateEmployee = {
              id: this.id,
              name: this.employee.name,
              email: this.employee.email,
              role_id: this.employee.role_id,
              outlet_id: this.employee.outlet_id,
              phone_number: this.employee.phone_number,
              address: this.employee.address,
              token: token,
              password: this.employee.password,
              confirmation: this.employee.confirmation,
            }
            await this.updateEmployee(updateEmployee);
            alert('Data pegawai berhasil diubah!');
            this.$router.push('/pegawai');
            return;
          }

          const updateEmployee = {
            id: this.id,
            name: this.employee.name,
            email: this.employee.email,
            role_id: this.employee.role_id,
            outlet_id: this.employee.outlet_id,
            phone_number: this.employee.phone_number,
            address: this.employee.address,
          }
          await this.updateEmployee(updateEmployee);
          alert('Data pegawai berhasil diubah!');
          this.$router.push('/pegawai');
        }
        catch (error) {
          alert('Failed to update employee', error);
        }
      }
      else {
        try {
          const newEmployee = {
            id: null,
            name: this.employee.name,
            email: this.employee.email,
            role_id: this.employee.role_id,
            outlet_id: this.employee.outlet_id,
            phone_number: this.employee.phone_number,
            address: this.employee.address,
            password: this.employee.password,
          }
          await this.createEmployee(newEmployee);
          alert('Data pegawai berhasil ditambah!');
          this.$router.push('/pegawai');
        }
        catch (error) {
          console.error('Failed to create employee', error);
          alert('Failed to create employee', error);
        }
      }
    }
  },
  async created() {
    this.$store.dispatch('outlets/fetchOutlets');
    this.$store.dispatch('roles/fetchRoles');

    const employeeId = this.id; 
    if (employeeId) {
      this.isEdit = true;

      try {
        const response = await this.fetchEmployeeById(employeeId);
        const employeeData = response.data;

        this.employee = {
          id: employeeData.id,
          name: employeeData.name,
          email: employeeData.email,
          role_id: employeeData.role_id,
          phone_number: employeeData.phone_number,
          address: employeeData.address,
          outlet_id: employeeData.outlet_id
        };
        this.originalPassword = employeeData.password;
      }
      catch (error) {
        console.error('Failed to fetch employee', error);
      }
    }
  }
}
</script>