<template>
   <section class="w-screen h-screen absolute flex justify-center items-center z-50">
      <div id="modal modal-customer" class="modal absolute block w-5/12 bg-white shadow-md rounded-lg"
         :class="{
         'animate__animated animate__zoomIn': showModal,
         'animate__animated animate__zoomOut': !showModal,
         }">
         <div id="modal-header" class="w-full bg-color-1 py-3 rounded-t-lg">
            <h5 class="text-white text-center font-bold text-xl">Masukkan Nama Pelanggan</h5>
         </div>
         <div id="modal-body" class="p-5">
            <div class="mb-5">
               <label for="" class="font-medium">Pilih Pelanggan</label>
               <select v-model="transaction.customer_id" :disabled="customer.firstName !== ''" id="nama-pelanggan" class="mt-1 col-span-1 disabled:bg-gray-300 text-gray-500 py-3 px-3 rounded-md border w-full block text-md">
                  <option value="">Pilih pelanggan</option>
                  <option
                     v-for="customer in allCustomers"
                     :key="customer.id"
                     :value="customer.id"
                  >
                     {{ customer.firstName + ' ' + customer.middleName + ' ' + customer.lastName }}
                  </option>
               </select>
            </div>
            <InputText v-model="customer.firstName" :disable_input="transaction.customer_id !== ''" label="Tambah Pelanggan Baru" input_class="w-full mb-5" placeholder="Pelanggan baru"/>
            <InputText v-model="customer.customer_location" label="Keterangan" input_class="w-full" placeholder="Nomor meja / Keterangan lokasi customer"/>
         </div>
         <div class="modal-footer p-5">
            <div class="flex justify-center">
               <SubmitButton id="close" button_name="Close" button_class="mx-1 text-red-600 border-red-600 hover:text-white hover:bg-red-600" @click="closeModal"/>
               <SubmitButton @click="submit" id="submit" button_name="Submit" button_class="mx-1 bg-color-1 hover:bg-color-2 text-color-4"/>
            </div>
         </div>
      </div>
   </section>
</template>
<script>
import InputText from '../form/InputText.vue';
import SubmitButton from '../form/SubmitButton.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
   name: 'ModalCustomer',
   components: { InputText, SubmitButton },
   data() {
      return {
         showModal: true,
         transaction: {
            id: null,
            transaction_code: '',
            customer_id: '',
            payment_status: 1,
            transaction_note: '',
            customer_location: '',
            tax: 0,
            service_charge: 0,
            subtotal: 0,
            grand_total: 0,
            service_items: [],
            order_items: []
         },
         customer: {
            id: null,
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            phone: '',
            address: ''
         }
      };
   },
   computed: {
      ...mapGetters('customers', ['allCustomers']),
   },
   created() {
      this.$store.dispatch('customers/fetchCustomers');
   },
   methods: {
      ...mapActions('transactions', ['createTransaction', 'fetchLastTransaction']),
      ...mapActions('customers', ['createCustomer', 'fetchLastCustomer']),

      closeModal() {
         this.showModal = false;
         this.$emit('closeModal');
         setTimeout(() => {
            this.$emit('closeModal');
         }, 300); 
      },
      async submit() {
         if(this.transaction.customer_id) {
            await this.createTransaction(this.transaction);
         }
         else if(this.customer.firstName) {
            await this.createCustomer(this.customer);
            const lastCustomer = await this.fetchLastCustomer();
            this.transaction.customer_id = lastCustomer.id;
            await this.createTransaction(this.transaction);
            console.log('New Transaction Created: ', this.transaction);
         }

         const lastTransaction = await this.fetchLastTransaction();
         console.log('last transaction: ', lastTransaction);
         this.$emit('transactionCreated', lastTransaction);

         this.closeModal();
      }
   }
}
</script>