<template>
  <IndexView/>
  <section class="p-6">
    <PageTitle title="Pengaturan" />
    <div class="tab-menu flex">
      <button class="py-2 px-4 hover:bg-blue-100" :class="{'bg-blue-100': activeTab === 'tab_metode_pembayaran'}" @click="changeTab('tab_metode_pembayaran')">
        Metode Pembayaran
      </button>
      <button class="py-2 px-4 hover:bg-blue-100" :class="{'bg-blue-100': activeTab === 'tab_pengaturan_2'}" @click="changeTab('tab_pengaturan_2')">
        Pengaturan 2
      </button>
    </div>
    <MetodePembayaranTab v-if="activeTab === 'tab_metode_pembayaran'" />
    <PengaturanDua v-if="activeTab === 'tab_pengaturan_2'" />
  </section>
</template>

<script>
import IndexView from '@/views/IndexView.vue';
import PageTitle from '@/components/PageTitle.vue';
import MetodePembayaranTab from '@/views/pages/setting/MetodePembayaranTab.vue';
import PengaturanDua from '@/views/pages/setting/PengaturanDuaTab.vue';

export default {
  name: "SettingView",
  components: {
    IndexView, PageTitle, MetodePembayaranTab, PengaturanDua
  },
  data() {
    return {
      activeTab: 'tab_metode_pembayaran'
    }
  },
  methods: {
    changeTab(target) {
      this.activeTab = target;
    }
  }
}
</script>
