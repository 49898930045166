import { createStore } from "vuex";
import employees from './modules/employees';
import products from './modules/products';
import categories from './modules/categories';
import customers from './modules/customers';
import payment_methods from './modules/payment_methods';
import outlets from './modules/outlets';
import roles from './modules/roles';
import units from './modules/units';
import attributes from './modules/attributes';
import attribute_lists from './modules/attribute_lists';
import inventories from './modules/inventories';
import detail_user from './modules/detail_user';
import attendances from './modules/attendances';
import transactions from './modules/transactions';
import pos_products from './modules/pos_products';
import pos_services from './modules/pos_services';
import pos_payments from './modules/pos_payments';

const store = createStore({
    modules: {
        employees,
        products,
        categories,
        customers,
        payment_methods,
        outlets,
        roles,
        units,
        attributes,
        attribute_lists,
        inventories,
        detail_user,
        attendances,
        transactions,
        pos_products,
        pos_services,
        pos_payments
    }
});

export default store;