<template>
  <ModalCustomer
    v-if="showModalCustomer"
    @closeModal="closeModal"
    @transactionCreated="updateLastTransaction"/>
  <IndexView />
  <div
    id="overlay"
    class="fixed inset-0 bg-black bg-opacity-30 z-30"
    :class="{ 'hidden': !isBlur }"
  ></div>
  <div class="grid grid-cols-12 gap-0 min-h-[calc(100vh-70px)]">
    <section id="products" class="col-span-8 bg-white shadow-xl overflow-y-scroll min-h-full">
      <TopBarProducts @setCurrentCategory="setCurrentCategory" />
      <div class="items-center flex justify-center h-3/4 text-4xl italic text-gray-400" v-if="filteredInventories <= 0">
        Belum ada produk
      </div>
      <div class="p-6 grid grid-cols-6 gap-8">
        <ProductCard v-for="inventory in filteredInventories"
          :key="inventory.id"
          :btnAddItem="'add-item-' + inventory.id"
          :productName="inventory?.Product?.name + ' ' + '(' + inventory?.InventoryAttributes[0]?.AttributeList?.name + ')'" 
          :productPrice="formatRupiah(inventory?.Product?.sale_price)"
          @click="addItemToOrder(inventory)"
        />
      </div>
    </section>
    <section id="orders" class="col-span-4 p-6 overflow-y-scroll min-h-full">
      <RouterLink to="/order_list" id="url_orderlist">
        <button class="py-2 border-2 border-color-1 text-base w-full rounded-md mb-2 hover:bg-color-1 hover:text-white">Daftar Transaksi</button>
      </RouterLink>
      <button id="add-transaction" @click="showCustomer" class="bg-color-1 text-white w-full p-3 font-semibold rounded-md text-xl">+ Tambah Transaksi</button>

      <!-- NAMA PELANGGAN dan KODE TRANSAKSI -->
      <div class="bg-white mt-3 rounded-md shadow-md p-4">
        <div v-if="transaction.payment_status == 2" class="bg-green-500 text-white text-center py-2 font-bold text-xl mb-5 rounded-md">
          Transaksi Telah Selesai
        </div>
        <div class="flex justify-between">
          <small>Nama Pelanggan : </small>
          <small class="text-gray-500 ">Kode Transaksi : {{ transactionCode }}</small>
        </div>
        <h3 id="customer-name" class="capitalize tracking-wide font-semibold text-2xl">{{ customerName }}</h3>
      </div>

      <!-- ORDER ITEM -->
      <div class="bg-white mt-3 rounded-md shadow-md p-4">
        <h3 class="font-semibold text-xl mb-3">Order Item</h3>
        <hr class="border mb-3">
        <div v-if="transaction.order_items.length <= 0" class="text-gray-400 italic text-center">No Item</div>
        <div v-if="transactionCode !== '---'">
          <div v-for="item in transaction.order_items" :key="item.id" :class="transaction.payment_status == 2 ? 'bg-gray-300' : 'bg-blue-100'" class="item w-full flex justify-between rounded-md p-3 mb-3">
            <div>
              <h4 class="item-name capitalize font-semibold text-base mb-2">{{ item.inventory_detail?.Product?.name }}</h4>
              <p class="item-price text-sm">{{ formatRupiah(item.subtotal) }}</p>
            </div>
            <div v-if="transaction.payment_status == 1" class="flex flex-col justify-between items-center">
              <div class="flex">
                <button @click="reduceQty(item)" :id="'reduce-qty-' + item.id">
                  <MinusCircleIcon class="h-6 w-6"/>
                </button>
                <input v-model="item.quantity" type="text" class="text-center w-10 mx-1 rounded-md" value="1" @input="updateSubtotalPerItem(item)" oninput="this.value = this.value.replace(/\D/g, '')">
                <button @click="addQty(item)" :id="'add-qty-' + item.id">
                  <PlusCircleIcon class="h-6 w-6"/>
                </button>
              </div>
              <button @click="removeItem(item)" :id="'remove-item' + item.id" class="text-red-600">Remove</button>
            </div>
          </div>
          <div v-if="notificationItem" class="mt-3 notif-discount-applied flex items-center p-3 bg-green-500 font-semibold text-white rounded-md mb-3">
            <CheckCircleIcon class="w-6 h-6 me-2" />
            Item berhasil disimpan
          </div>
          <button
            v-if="showSaveOrderButton"
            @click="saveOrderItem()"
            id="save-order"
            :disabled="transaction.payment_status == 2"
            :class="transaction.payment_status == 2 ? 'bg-gray-400 text-white' : 'bg-color-1 text-color-4'"
            class="w-full mt-3 py-2 rounded-md font-bold capitalize text-lg ">Simpan Order
          </button>
        </div>
      </div>

      <!-- DISKON DAN CATATAN -->
      <div v-if="transactionCode !== '---'">
        <div class="bg-white rounded-md shadow-md mt-3 p-4">
          <div v-if="notification" class="notif-discount-applied flex items-center p-3 bg-green-500 font-semibold text-white rounded-md mb-3">
            <CheckCircleIcon class="w-6 h-6 me-2" />
            Diskon berhasil diterapkan
          </div>
          <div class="mb-3 flex items-end">
            <div class="w-full relative">
              <InputText :disable_input="isDiscountApplied" v-model="transaction.discount" type="number" id="transaction-discount" label="Diskon" input_class="w-full" placeholder="Cth: 10"/>
              <span class="absolute bottom-0 right-6 py-2 px-3 font-semibold">%</span>
            </div>
            <button
              id="apply-discount"
              @click="applyDiscount"
              :disabled="isDiscountApplied || transaction.payment_status == 2"
              :class="[isDiscountApplied || transaction.payment_status == 2? 'bg-gray-400' : 'bg-color-1']"
              class="text-white relative py-2 px-4 rounded-md mx-2"> {{ isDiscountApplied ? 'Applied' : 'Apply' }}
            </button>
            <button
              id="cancel-discount"
              v-if="isDiscountApplied"
              @click="removeDiscount"
              class="relative rounded-md text-xs text-red-600"> Batalkan diskon
            </button>
          </div>
          <TextArea v-model="transaction.transaction_note" id="transaction-note" label="Catatan : " :rows="2"/>

          <!-- SUBTOTAL -->
          <div class="rounded-md p-3 mt-5" :class="transaction.payment_status == 2 ? 'bg-gray-300' : 'bg-color-2'">
            <p class="text-white">Subtotal : </p>
            <template v-if="subtotalBeforeDiscount > subtotalAfterDiscount">
              <span class="line-through font-light text-xs text-white">{{ formatRupiah(subtotalBeforeDiscount) }}</span>
              <h4 class="text-white font-bold text-2xl">{{ formatRupiah(subtotalAfterDiscount) }}</h4>
            </template>
            <h4 v-if="subtotalBeforeDiscount == subtotalAfterDiscount" class="text-white font-bold text-2xl">{{ formatRupiah(subtotalAfterDiscount) }}</h4>
          </div>
        </div>

        <!-- CHECKOUT -->
        <button
          @click="checkout()"
          id="checkout"
          :class="transaction.order_items <= 0 || transaction.payment_status == 2? 'bg-gray-400 text-white' : 'bg-gradient-to-r from-color-1 to-color-5 text-color-4'"
          :disabled="transaction.order_items <= 0 || transaction.payment_status == 2"
          class="mt-3 mb-5 shadow-md w-full items-center  justify-between flex px-4 py-6 rounded-md font-bold text-2xl">
          Checkout
          <div class="flex items-center">
            <span class="font-light text-base">{{ formatRupiah(subtotalAfterDiscount) }}</span>
            <ChevronRightIcon v-if="transaction.payment_status == 1" class="w-8 h-8" :class="transaction.order_items <= 0 ? 'text-white' : 'text-color-4'"/>
          </div>
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import IndexView from '@/views/IndexView.vue';
import ProductCard from '@/components/ProductCard.vue';
import TopBarProducts from '@/components/TopBarProducts.vue';
import ModalCustomer from '@/components/modals/ModalCustomer.vue';
import TextArea from '@/components/form/TextArea.vue';
import InputText from '@/components/form/InputText.vue';
import { formatRupiah } from '@/helpers/formatRupiah';
import { mapGetters, mapActions } from 'vuex';
import { MinusCircleIcon, PlusCircleIcon, ChevronRightIcon, CheckCircleIcon} from '@heroicons/vue/24/solid';

export default {
  name: 'DashboardView',
  props: ['id'],
  components: {
    IndexView, ProductCard, TopBarProducts, ModalCustomer, TextArea, InputText,
    MinusCircleIcon, PlusCircleIcon, ChevronRightIcon, CheckCircleIcon
  },
  data() {
    return {
      isBlur: false,
      notification: false,
      notificationItem: false,
      isDiscountApplied: false,
      showSaveOrderButton: false,
      showModalCustomer: false,
      subtotalBeforeDiscount: 0,
      subtotalAfterDiscount: 0,

      currentCategory: null,
      customerName: '---',
      transactionCode: '---',
      transactionSubtotal: 0,
      transaction: {
        id: null,
        transaction_code: '',
        customer_id: null,
        outlet_id: null,
        payment_status: 1,
        discount: 0,
        discount_note:'',
        transaction_note: '',
        customer_location: '',
        tax: 0,
        service_charge: 0,
        subtotal: 0,
        grand_total: 0,
        customer: {
          id: null,
          firstName: '',
          middleName: '',
          lastName: ''
        },
        service_items: [],
        order_items: []
      },
      customer: {
        id: null,
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        phone: '',
        address: ''
      }
    }
  },
  computed: {
    ...mapGetters('products', ['allProducts']),
    ...mapGetters('categories', ['allCategories']),
    ...mapGetters('inventories', ['allInventories']),

    filteredInventories() {
      if (this.currentCategory == null) {
        return this.allInventories;
      }
      return this.allInventories.filter(inventory => 
        inventory?.Product?.category_id == this.currentCategory.id
      );
    }
  },
  methods: {
    ...mapActions('transactions', ['createTransaction', 'updateTransaction', 'fetchTransactionById']),
    ...mapActions('pos_products', ['createPosProduct', 'updatePosProduct', 'deletePosProductById']),
    ...mapActions('pos_services', ['createPosService', 'updatePosService', 'deletePosServiceById']),

    formatRupiah,
    setCurrentCategory(category) {
      this.currentCategory = category;
    },
    showCustomer() {
      this.showModalCustomer = true;
      this.isBlur = true;
    },
    closeModal() {
      setTimeout(() => {
        this.showModalCustomer = false;
        this.isBlur = false;
      }, 300);
    },
    updateLastTransaction(transaction) {
      this.transaction = transaction;

      if(transaction?.customer?.firstName) {
        this.customerName = transaction?.customer?.firstName + ' ' + transaction?.customer?.middleName;
      }
      else {
        this.customerName = 'No name';
      }
      this.transactionCode = transaction.transaction_code;
      this.updateSubtotalTransaction();
    },
    addItemToOrder(item) {
      console.log("add item to order: ", item);
      this.showSaveOrderButton = true;
      const orderItem = {
        id: null,
        order_note: '',
        discount: 0,
        transaction_id: this.transaction.id,
        transaction_type: 1,
        price_per_unit: item.Product.sale_price,
        quantity: 1,
        subtotal: item.Product.sale_price,
        inventory_id: item.id,
        inventory_detail: {
          Product: {
            id: item.Product.id,
            name: item.Product.name + ' (' + item?.InventoryAttributes[0]?.AttributeList?.name + ')'
          }
        }
      };
      this.transaction.order_items.push(orderItem);
      this.updateSubtotalTransaction();
      console.log('added item to order: ', this.transaction.order_items);
      console.log('update transaction: ', this.transaction);
    },
    updateSubtotalPerItem(item){
      item.subtotal = parseFloat(item.price_per_unit) * parseFloat(item.quantity);
      this.updateSubtotalTransaction();
    },
    updateSubtotalTransaction(){
      let transaction_subtotal = 0;
      
      if(this.transaction.order_items.length > 0) {
        this.transaction.order_items.forEach(item => {
          if(item.subtotal) {
            transaction_subtotal += parseFloat(item.subtotal);
          }
        });
      }
      
      if(this.transaction.service_items.length > 0) {
        this.transaction.service_items.forEach(item => {
          if(item.subtotal) {
            transaction_subtotal += parseFloat(item.subtotal);
          }
        });
      }
      

      this.subtotalBeforeDiscount = transaction_subtotal;

      if(this.transaction.discount) {
        transaction_subtotal = (1 - (this.transaction.discount / 100)) * transaction_subtotal;
      }

      this.subtotalAfterDiscount = transaction_subtotal;
      console.log('updateSubtotalTransaction', this.transaction, this.subtotalBeforeDiscount, this.subtotalAfterDiscount);
    },
    applyDiscount() {
      let discount = this.transaction.discount;
      if (typeof discount === 'string' && discount.includes('%')) {
        discount = parseFloat(discount.replace('%', '')) / 100;
      }
      else {
        discount = parseFloat(discount) / 100;
      }
      
      if(this.id) {
        this.subtotalBeforeDiscount = this.transaction.subtotal;
        this.subtotalAfterDiscount = this.subtotalBeforeDiscount * (1 - discount);
      }
      else {
        this.subtotalAfterDiscount = this.subtotalBeforeDiscount * (1 - discount);
      }

      this.isDiscountApplied = true;
      this.notification = true;

      console.log('Applied Discount: ', this.transaction, this.subtotalBeforeDiscount, this.subtotalAfterDiscount);

      setTimeout(() => {
        this.notification = false;
      }, 2000);
    },
    removeDiscount() {
      this.transaction.discount = 0;
      this.updateSubtotalTransaction();

      console.log('Removed Discount: ', this.transaction);
      this.isDiscountApplied = false;
    },
    reduceQty(item) {
      this.showSaveOrderButton = true;
      if(item.quantity > 0) {
        item.quantity--;
        this.updateSubtotalPerItem(item);
      }
    },
    addQty(item) {
      this.showSaveOrderButton = true;
      item.quantity++;
      this.updateSubtotalPerItem(item);
    },
    removeItem(item) {
      if(this.id && (this.transaction.order_items.length > 1 || this.transaction.service_items.length > 1)) {
        console.log("> 1", this.transaction.order_items.length)
        this.showSaveOrderButton = true;
      }
      else if(this.id && (this.transaction.order_items.length <= 1 || this.transaction.service_items.length <= 1)) {
        console.log("<= 1")
        this.showSaveOrderButton = false;
      }

      this.updateSubtotalPerItem(item);

      console.log("Removed Item: ", this.transaction);

      const indexOrderItem = this.transaction?.order_items.indexOf(item);
      if(indexOrderItem !== -1) {
        this.transaction.order_items.splice(indexOrderItem, 1);
        this.updateSubtotalTransaction();
      }

      const indexServiceItem = this.transaction?.service_items.indexOf(item);
      if(indexServiceItem !== -1) {
        this.transaction.service_items.splice(indexServiceItem, 1);
        this.updateSubtotalTransaction();
      }
    },
    async saveOrderItem() {
      // === EDIT & UPDATE ORDER ITEMS & SERVICE ITEMS
      if(this.id) {
        console.log('new Transaction: ', this.transaction);
        const responseDataTransaction = await this.fetchTransactionById(this.transaction.id);

        // HANDLE ORDER Item
        const updatedDataOrderItems = this.transaction.order_items;
        const fetchedOrderItems = responseDataTransaction.data.order_items;

        // CHECK For deleted ORDER items
        const updatedOrderItemIds = updatedDataOrderItems.map(item => item.id);
        const deletedOrderItems = fetchedOrderItems.filter(fetchedOrderItem => !updatedOrderItemIds.includes(fetchedOrderItem.id));
        for (let item of deletedOrderItems) {
          await this.deletePosProductById(item.id);
        }

        // CHECK For create and update ORDER items
        for(let item of updatedDataOrderItems) {
          if(item.id == null) {
            console.log('item: ', item);
            const modifiedItem = {
              orderItems: [],
              transaction_id: item.transaction_id,
              transaction_type: item.transaction_type,
              inventory_id: item.inventory_id,
              quantity: item.quantity,
              price_per_unit: item.price_per_unit,
              discount: item.discount,
              subtotal: item.subtotal,
              order_note: item.order_note
            }
            await this.createPosProduct(modifiedItem);
          }
          else {
            await this.updatePosProduct(item);
          }
        }

        // HANDLE SERVICE Item
        const updatedDataServiceItems = this.transaction.service_items;
        const fetchedServiceItems = responseDataTransaction.data.service_items;

        // CHECK For deleted SERVICE items
        const updatedServiceItemIds = updatedDataServiceItems.map(item => item.id);
        const deletedServiceItems = fetchedServiceItems.filter(fetchedServiceItem => !updatedServiceItemIds.includes(fetchedServiceItem.id));
        for (let item of deletedServiceItems) {
          await this.deletePosServiceById(item.id);
        }

        // CHECK For create and update SERVICE items
        for(let item of updatedDataServiceItems) {
          if(item.id == null) {
            const modifiedItem = {
              serviceItems: [],
              transaction_id: item.transaction_id,
              transaction_type: item.transaction_type,
              inventory_id: item.inventory_id,
              quantity: item.quantity,
              price_per_unit: item.price_per_unit,
              discount: item.discount,
              subtotal: item.subtotal,
              order_note: item.order_note
            }
            await this.createPosService(modifiedItem);
          }
          else {
            await this.updatePosService(item);
          }
        }
      }

      // === ADD ORDER ITEMS & SERVICE ITEMS
      else {
        if(this.transaction.order_items !== null) {
          const orderProducts = {
            transaction_id: this.transaction.id,
            orderItems: this.transaction.order_items
          }
          await this.createPosProduct(orderProducts);
          console.log('Berhasil Create POS Order Item!');
        }
        if(this.transaction.service_items !== null){
          const serviceProducts = {
            transaction_id: this.transaction.id,
            serviceItems: this.transaction.service_items
          }
          await this.createPosService(serviceProducts);
          console.log('Berhasil Create POS Service Item!');
        }
      }
      this.notificationItem = true;
      setTimeout(() => {
        this.notificationItem = false;
      }, 2000);
    },
    async checkout() {
      // const updatedTransaction = {
      //   id: this.transaction.id,
      //   customer_location: this.transaction.customer_location,
      //   discount: this.transaction.discount,
      //   discount_note: this.transaction.discount_note,
      //   transaction_note: this.transaction.transaction_note,
      //   subtotal: this.subtotalAfterDiscount,
      //   tax: this.transaction.tax,
      //   service_charge: this.service_charge
      // };

      this.transaction.subtotal = this.subtotalAfterDiscount;
      await this.updateTransaction(this.transaction);

      console.log('Checkout: ', this.transaction, this.subtotalAfterDiscount);
      console.log('Berhasil Checkout Transaksi!');

      this.$router.push({ name: 'CheckoutView', params: { id: this.transaction.id } });
    }
  },
  async created() {
    this.$store.dispatch('products/fetchProducts');
    this.$store.dispatch('categories/fetchCategories');
    this.$store.dispatch('inventories/fetchInventories');

    const transaction_id = this.id;
    if(transaction_id) {
      try {
        const response = await this.fetchTransactionById(transaction_id);
        const dataTransaction = response.data;
        this.updateLastTransaction(dataTransaction);

        this.subtotalBeforeDiscount = dataTransaction.subtotal;
        if(dataTransaction.discount) {
          this.isDiscountApplied = true;
          this.subtotalAfterDiscount = (1 - (dataTransaction.discount / 100)) * dataTransaction.subtotal;
        }
        else {
          this.subtotalAfterDiscount = dataTransaction.subtotal;
        }
        console.log('Transaksi dari params: ', dataTransaction);
        console.log('subtotalBeforeDiscount: ', this.subtotalBeforeDiscount);
        console.log('subtotalAfterDiscount: ', this.subtotalAfterDiscount);
      }
      catch (error) {
        console.error('Failed to fetch transaction', error);
      }
    }
  },
}
</script>

<style>
#products{
  box-shadow: 5px 10px 15px rgba(0,0,0,0.2);
}
</style>