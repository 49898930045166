<template>
  <section class="w-screen h-screen absolute flex justify-center items-center z-50">
    <div id="modal modal-customer" class="modal absolute block w-5/12 bg-white shadow-md rounded-lg"
      :class="{
      'animate__animated animate__zoomIn': showModal,
      'animate__animated animate__zoomOut': !showModal,
      }">
      <div id="modal-header" class="w-full bg-color-1 py-3 rounded-t-lg">
        <h5 class="text-white text-center font-bold text-xl">{{ isEdit ? 'Edit Atribut List' : 'Tambah Atribut List' }}</h5>
      </div>
      <div id="modal-body" class="p-5">
        <div class="mb-3">
          <label class="font-medium">Master Atribut</label>
          <select id="master-attribute" v-model="attributeId" class="w-full border border-gray-400 rounded-md px-3 py-2 text-color-1">
            <option selected>Pilih atribut</option>
            <option v-for="attribute in allAttributes" :key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
          </select>
        </div>
        <InputText id="attribute-list-name" label="Nama Atribut List" input_class="w-full" v-model="attributeListName"/>
      </div>
      <div class="modal-footer p-5">
        <div class="flex justify-center">
          <SubmitButton id="close" button_name="Close" button_class="mx-1 text-red-600 border-red-600 hover:text-white hover:bg-red-600" @click="closeModal"/>
          <SubmitButton id="submit" button_name="Submit" button_class="mx-1 bg-color-1 hover:bg-color-2 text-color-4" @click="submitAttributeList"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import InputText from '../form/InputText.vue';
import SubmitButton from '../form/SubmitButton.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "ModalAttribute",
  props: {
    attribute_list: {
      type: Object,
      default: null,
    }
  },
  components: {
    InputText, SubmitButton
  },
  data() {
    return {
      showModal: true,
      attributeListName: this.attribute_list ? this.attribute_list.name : '',
      attributeId: this.attribute_list ? this.attribute_list.Attribute.id : '',
      isEdit: !!this.attribute_list
    };
  },
  watch: {
    attribute_list(newValue) {
      if (newValue) {
        this.attributeListName = newValue.name;
        this.attributeId = newValue.Attribute.id;
        this.isEdit = true;
      } else {
        this.attributeListName = '';
        this.attributeId = '';
        this.isEdit = false;
      }
    }
  },
  methods: {
    ...mapActions('attribute_lists', ['createAttributeList', 'updateAttributeList']),
    closeModal() {
      this.showModal = false;
      this.$emit('closeModal');
      setTimeout(() => {
        this.$emit('closeModal');
      }, 300);
    },
    async submitAttributeList() {
      try {
        const newAttributeList = {
          name: this.attributeListName,
          attribute_id: this.attributeId
        };

        if(this.isEdit && this.attribute_list) {
          newAttributeList.id = this.attribute_list.id;
          await this.updateAttributeList(newAttributeList)
        } else {
          await this.createAttributeList(newAttributeList);
        }
        this.closeModal();
      }
      catch (error) {
        console.error('Failed to create attribute list: ', error);
      }
    }
  },
  computed: {
    ...mapGetters('attributes', ['allAttributes'])
  },
  created() {
    this.$store.dispatch('attributes/fetchAttributes');
  }
}
</script>
