import axios from 'axios';

const state = {
  transactions: []
}

const actions = {
  async fetchTransactions({ commit }) {
    try {
      const token = localStorage.getItem('auth_token');
      const response = await axios.get(`${process.env.VUE_APP_ROOT_API}/pos-transactions`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.data;
      commit('setTransactions', data);
    }
    catch (error) {
      console.error('Failed to fetch Transactions: ', error);
    }
  },
  createTransaction({ commit }, transaction) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('auth_token');
      axios.post(`${process.env.VUE_APP_ROOT_API}/pos-transactions`, transaction, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        const data = response.data;
        commit('addTransaction', data);
        resolve(data);
      })
      .catch(error => {
        console.error('Failed to create transaction', error);
        reject(error);
      });
    });
  },
  async fetchLastTransaction({ dispatch, state }) {
    await dispatch('fetchTransactions');
    const transactions = state.transactions;
    if (transactions.length === 0) return null;
    return transactions.reduce((prev, current) => (prev.id > current.id) ? prev : current);
  },
  async updateTransaction({ commit }, transaction) {
    try {
      const token = localStorage.getItem('auth_token');
      const response = await axios.put(`${process.env.VUE_APP_ROOT_API}/pos-transactions/${transaction.id}`, transaction, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      commit('updateTransaction', response.data);
    } catch (error) {
      console.error('Failed to update Transaction:', error);
      throw error;
    }
  },
  async fetchTransactionById(_, id) {
    try {
      const token = localStorage.getItem('auth_token');
      const response = await axios.get(`${process.env.VUE_APP_ROOT_API}/pos-transactions/${id}`, {
        headers: {
        'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch transaction:', error);
      throw error;
    }
  },
}

const mutations = {
  setTransactions(state, transactions) {
    state.transactions = transactions.data;
  },
  addTransaction(state, transaction) {
    state.transactions = transaction.data;
  },
  updateTransaction(state, updatedTransaction) {
    const index = state.transactions.findIndex(transaction => transaction.id == updatedTransaction.id);
    if (index !== -1) {
      state.customers.splice(index, 1, updatedTransaction);
    }
  }
}

const getters = {
  allTransactions: (state) => state.transactions,
}

export default {
  namespaced: true,
  state, mutations, actions, getters
}