<template>
  <IndexView />
  <section class="p-6">
    <PageTitle title="Data Pegawai" title_class="mb-3"/>
    <div class=" bg-white rounded-lg py-6 px-5 shadow-lg">
      <div id="table-header" class="mb-5 flex justify-between">
        <AddButton id="add-data" to_url="/pegawai/tambah-pegawai" button_text="+ Tambah Pegawai" button_class="text-base py-2 font-medium"/>
        <div>
          <InputText placeholder="Cari..." input_class="w-80"/>
        </div>
      </div>
      <table class="table-auto w-full">
        <thead class="bg-color-1">
          <tr>
            <th class="py-3 px-3 border-b text-color-4 text-left">No</th>
            <th class="py-3 px-3 border-b text-color-4 text-left">Nama Pegawai</th>
            <th class="py-3 px-3 border-b text-color-4 text-left">Role</th>
            <th class="py-3 px-3 border-b text-color-4 text-left">Email</th>
            <th class="py-3 px-3 border-b text-color-4 text-left">No Telepon</th>
            <th class="py-3 px-3 border-b text-color-4 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="allEmployees.length == 0">
            <td colspan="6" class="p-3 text-center italic text-gray-400">Belum ada data</td>
          </tr>
          <tr v-else v-for="(employee, index) in allEmployees" :key="employee.id" :class="{'bg-blue-50' : index % 2 == 1}" class="hover:bg-blue-100">
            <td class="py-3 px-3 text-gray-500">{{ index + 1 }}</td>
            <td class="py-3 px-3 text-gray-500">{{ employee.name }}</td>
            <td class="py-3 px-3 text-gray-500">{{ employee.Role ? employee.Role.name : '' }}</td>
            <td class="py-3 px-3 text-gray-500">{{ employee.email }}</td>
            <td class="py-3 px-3 text-gray-500">{{ employee.phone_number }}</td>
            <td class="py-3 px-3 text-gray-500 flex">
              <button :id="'edit-data-' + employee.id" @click="editEmployee(employee)" class="border border-gray-400 hover:border-green-500 hover:bg-green-400 flex justify-center items-center mx-1 p-2 rounded-md group">
                <PencilSquareIcon class="h-4 w-4 group-hover:text-green-700"/>
              </button>
              <button :id="'delete-data-' + employee.id" @click="deleteEmployee(employee)" class="border border-gray-400 hover:border-red-500 hover:bg-red-400 flex justify-center items-center mx-1 p-2 rounded-md group">
                <TrashIcon class="h-4 w-4 group-hover:text-red-700"/>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import IndexView from '@/views/IndexView.vue';
import PageTitle from '@/components/PageTitle.vue';
import AddButton from '@/components/buttons/AddButton.vue';
import InputText from '@/components/form/InputText.vue';
import { mapGetters, mapActions } from 'vuex';
import { PencilSquareIcon, TrashIcon } from '@heroicons/vue/24/solid';

export default {
  name: "EmployeeView",
  components: {
    IndexView, PageTitle, AddButton, InputText,
    TrashIcon, PencilSquareIcon
  },
  computed: {
    ...mapGetters('employees', ['allEmployees'])
  },
  created() {
    this.$store.dispatch('employees/fetchEmployees');
  },
  methods: {
    ...mapActions('employees', ['deleteEmployeeById']),
    editEmployee(employee) {
      this.$router.push({ name: 'EditEmployee', params: { id: employee.id } });
    },
    async deleteEmployee(employee) {
      if(confirm(`Yakin ingin menghapus pegawai ${employee.name}?`)) {
        try {
          await this.deleteEmployeeById(employee.id);
          alert('Pegawai berhasil dihapus');
          this.$store.dispatch('employees/fetchEmployees');
        }
        catch (error) {
          console.error('Gagal menghapus pegawai', error);
          alert('Gagal menghapus pegawai');
        }
      }
    }
  }
}
</script>