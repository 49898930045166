export const globalVar = {
   employees: [
      { id: 1, role_id: 'kasir', name: 'Musa Al Khawarizmi', email: 'musa@gmail.com', no_telp: '0987654321', address: 'surabaya', password: '11221122' },
      { id: 2, role_id: 'kasir', name: 'John Dalton', email: 'dalton@gmail.com', no_telp: '0987654321', address: 'kediri', password: '12345678' },
      { id: 3, role_id: 'kasir', name: 'Raden Sahid', email: 'sahid@gmail.com', no_telp: '0987654321', address: 'lamongan', password: '22112211' },
      { id: 4, role_id: 'kasir', name: 'Thomas Alva Edison', email: 'edison@gmail.com', no_telp: '0987654321', address: 'malang', password: '87654321' },
   ],
   categories: [
      {id: 1, name: 'Minuman'},
      {id: 2, name: 'Makanan'},
   ],
   products: [
      { id:1, category_id: 'Minuman', brand_id: '', unit_id: '', product_code: 'MIN01', name: 'Kopi Hitam', capital: '', selling_price: '4000', final_price: '', discount: '', stock: '' },
      { id:2, category_id: 'Minuman', brand_id: '', unit_id: '', product_code: 'MIN02', name: 'Kopi Susu', capital: '', selling_price: '6000', final_price: '', discount: '', stock: '' },
      { id:3, category_id: 'Minuman', brand_id: '', unit_id: '', product_code: 'MIN03', name: 'Kopi Gula Aren', capital: '', selling_price: '5000', final_price: '', discount: '', stock: '' },
      { id:4, category_id: 'Minuman', brand_id: '', unit_id: '', product_code: 'MIN04', name: 'Es Teh', capital: '', selling_price: '3000', final_price: '', discount: '', stock: '' },
      { id:5, category_id: 'Makanan', brand_id: '', unit_id: '', product_code: 'MAK01', name: 'Nasi Goreng', capital: '', selling_price: '12000', final_price: '', discount: '', stock: '' },
      { id:6, category_id: 'Makanan', brand_id: '', unit_id: '', product_code: 'MAK02', name: 'Mie Godog', capital: '', selling_price: '11000', final_price: '', discount: '', stock: '' },
      { id:7, category_id: 'Makanan', brand_id: '', unit_id: '', product_code: 'MAK03', name: 'Bakso', capital: '', selling_price: '10000', final_price: '', discount: '', stock: '' },
      { id:8, category_id: 'Makanan', brand_id: '', unit_id: '', product_code: 'MAK04', name: 'Mie Ayam', capital: '', selling_price: '8000', final_price: '', discount: '', stock: '' },
   ],
}