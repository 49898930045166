<template>
  <div class="card flex flex-col justify-center items-center shadow-md bg-white rounded-md py-5 cursor-pointer">
    <img alt="User Logo" src="../assets/images/icon-user.png" class="mb-5 w-28">
  </div>
</template>

<script>
export default {
  name: 'UserCard',
}
</script>