<template>
  <IndexView />
  <section class="p-6">
    <PageTitle title="Data Pelanggan"/>
    <div class="bg-white rounded-lg py-6 px-5 shadow-lg">
      <div id="table-header" class="mb-5 flex justify-between">
        <AddButton to_url="/pelanggan/tambah-pelanggan" button_text="+ Tambah Pelanggan" button_class="text-base py-2 font-medium"/>
        <div>
          <InputText placeholder="Cari..." input_class="w-80"/>
        </div>
      </div>
      <table class="table-auto w-full">
        <thead class="bg-color-1">
          <tr>
            <th class="py-3 px-3 border-b text-color-4 text-left">No</th>
            <th class="py-3 px-3 border-b text-color-4 text-left">Nama Pelanggan</th>
            <th class="py-3 px-3 border-b text-color-4 text-left">Email</th>
            <th class="py-3 px-3 border-b text-color-4 text-left">No Telepon</th>
            <th class="py-3 px-3 border-b text-color-4 text-left">Points</th>
            <th class="py-3 px-3 border-b text-color-4 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="allCustomers.length == 0">
            <td colspan="6" class="p-3 text-center italic text-gray-400">Belum ada data</td>
          </tr>
          <tr v-else v-for="(customer, index) in allCustomers" :key="customer.id" :class="{'bg-blue-50' : index % 2 == 1}" class="hover:bg-blue-100">
            <td class="py-3 px-3">{{ index + 1 }}</td>
            <td class="py-3 px-3">{{ customer.firstName + ' ' + customer.middleName + ' ' + customer.lastName }}</td>
            <td class="py-3 px-3">{{ customer.email }}</td>
            <td class="py-3 px-3">{{ customer.phone }}</td>
            <td class="py-3 px-3">{{ customer.credit }}</td>
            <td class="py-3 px-3 text-gray-500 flex">
              <button :id="'edit-data-' + customer.id" class="border border-gray-400 hover:border-green-500 hover:bg-green-400 flex justify-center items-center mx-1 p-2 rounded-md group">
                <PencilSquareIcon @click="editCustomer(customer)" class="h-4 w-4 group-hover:text-green-700"/>
              </button>
              <button :id="'delete-data-' + customer.id" @click="deleteCustomer(customer)" class="border border-gray-400 hover:border-red-500 hover:bg-red-400 flex justify-center items-center mx-1 p-2 rounded-md group">
                <TrashIcon class="h-4 w-4 group-hover:text-red-700"/>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import IndexView from '@/views/IndexView.vue';
import PageTitle from '@/components/PageTitle.vue';
import InputText from '@/components/form/InputText.vue';
import AddButton from '@/components/buttons/AddButton.vue';
import { mapGetters, mapActions } from 'vuex';
import { PencilSquareIcon, TrashIcon } from '@heroicons/vue/24/solid';

export default {
  name: "CustomerView",
  components: {
    IndexView, PageTitle, InputText, AddButton,
    PencilSquareIcon, TrashIcon
  },
  computed: {
    ...mapGetters('customers', ['allCustomers'])
  },
  created() {
    this.$store.dispatch('customers/fetchCustomers');
  },
  methods: {
    ...mapActions('customers', ['deleteCustomerById']),
    editCustomer(customer) {
      this.$router.push({ name: 'EditCustomer', params: { id: customer.id } });
    },
    async deleteCustomer(customer) {
      if(confirm(`Yakin menghapus customer ${customer.firstName}?`)) {
        try {
          await this.deleteCustomerById(customer.id);
          alert('Customer berhasil dihapus');
          this.$store.dispatch('customers/fetchCustomers');
        }
        catch (error) {
          console.error('Failed to delete customer', error);
          alert('Gagal menghapus customer');
        }
      }
    }
  }
}
</script>