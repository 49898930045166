<template>
    <div class="flex w-full relative">
        <div @click="getAllProduct()" class="w-1/2 text-center py-2 font-medium border-color-2 border-r hover:bg-color-1 hover:text-white cursor-pointer">Produk</div>
        <div @click="getAllService()" class="w-1/2 text-center py-2 font-medium border-color-2 border-l hover:bg-color-1 hover:text-white cursor-pointer">Layanan</div>
      </div>
    <div class="topbar-products bg-color-4 h-16 flex items-center overflow-x-scroll px-5">
        <span
            @click="setCurrentCategory(null)"
            :class="{'bg-color-1 text-color-4' :currentCategory === null}"
            class="rounded-md py-2 px-5 capitalize cursor-pointer">All
        </span>
        <span
            v-for="category in allCategories"
            :key="category.id"
            @click="setCurrentCategory(category)"
            :class="{'bg-color-1 text-color-4': currentCategory?.id === category.id}"
            class="rounded-md py-2 px-5 capitalize cursor-pointer">{{ category.name }}
        </span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'TopBarProducts',
    data() {
        return {
            currentCategory: null
        }
    },
    created() {
        this.$store.dispatch('categories/fetchCategories');
        this.$store.dispatch('products/fetchProducts');
    },
    methods: {
        setCurrentCategory(category) {
            this.currentCategory = category;
            this.$emit('setCurrentCategory', category);
        },
    },
    computed: {
        ...mapGetters('categories', ['allCategories']),
        ...mapGetters('products', ['allProducts']),
    }
}
</script>