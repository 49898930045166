<template>
    <div class="flex justify-start">
        <RouterLink :to="to_url" id="back-button" class="text-left text-black border border-gray-800 rounded-md  text-sm py-2 px-3 flex items-center justify-center hover:text-white hover:bg-black">
            <ArrowLeftIcon class="w-4 h-4 mr-1"/>
            Kembali
        </RouterLink>
    </div>
</template>

<script>
import { ArrowLeftIcon } from '@heroicons/vue/24/solid';

export default {
    name: 'BackButton',
    components: { ArrowLeftIcon },
    props: {
        to_url: String
    }
}
</script>