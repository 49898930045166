<template>
  <IndexView/>
  <section class="p-6">
    <PageTitle title="Kelola Produk" />
    <div class="bg-white rounded-lg py-6 px-5 shadow-lg">
      <div id="table-header" class="mb-5 flex justify-between">
        <AddButton to_url="/kelola-produk/tambah-produk" button_text="+ Tambah Produk" button_class="text-base font-medium"/>
        <div>
          <InputText placeholder="Cari ..." input_class="w-96"/>
        </div>
      </div>
      <table class="table-auto w-full">
        <thead class="bg-color-1">
          <tr>
            <th class="p-3 border-b text-color-4 text-left">No</th>
            <th class="p-3 border-b text-color-4 text-left">Kode</th>
            <th class="p-3 border-b text-color-4 text-left">Untuk Dijual</th>
            <th class="p-3 border-b text-color-4 text-left">Kategori</th>
            <th class="p-3 border-b text-color-4 text-left">Produk</th>
            <th class="p-3 border-b text-color-4 text-left">Harga Beli</th>
            <th class="p-3 border-b text-color-4 text-left">Harga Jual</th>
            <th class="p-3 border-b text-color-4 text-left">Stok</th>
            <th class="p-3 border-b text-color-4 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr class="border-b hover:bg-blue-100" v-for="(product, index) in allProducts" :key="product.id" :class="{'bg-blue-50' : index % 2 == 1}" clas>
            <td class="p-3 text-gray-500">{{ index + 1 }}</td>
            <td class="p-3 text-gray-500">{{ product.code }}</td>
            <td class="p-3 text-gray-500">{{ product.saleable == true ? 'Iya' : 'Tidak' }}</td>
            <td class="p-3 text-gray-500">{{ product.is_service == true ? 'Layanan' : 'Produk' }}</td>
            <td class="p-3 text-gray-500">{{ product.name }}</td>
            <td class="p-3 text-gray-500">{{ formatRupiah(product.purchase_price) }}</td>
            <td class="p-3 text-gray-500">{{ formatRupiah(product.sale_price) }}</td>
            <td class="p-3 text-gray-500">{{ product.qty }}</td>
            <td class="p-3 text-gray-500 flex">
              <button :id="'edit-data-' + product.id" @click="editProduct(product)" class="border border-gray-400 hover:border-green-500 hover:bg-green-400 flex justify-center items-center mx-1 p-2 rounded-md group">
                <PencilSquareIcon class="h-4 w-4 group-hover:text-green-700"/>
              </button>
              <button :id="'delete-data-' + product.id" @click="deleteProduct(product)" class="border border-gray-400 hover:border-red-500 hover:bg-red-400 flex justify-center items-center mx-1 p-2 rounded-md group">
                <TrashIcon class="h-4 w-4 group-hover:text-red-700"/>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import IndexView from '@/views/IndexView.vue';
import PageTitle from '@/components/PageTitle.vue';
import InputText from '@/components/form/InputText.vue';
import AddButton from '@/components/buttons/AddButton.vue';
import { PencilSquareIcon, TrashIcon } from '@heroicons/vue/24/solid';
import { formatRupiah } from '@/helpers/formatRupiah';
import { mapGetters, mapActions } from 'vuex';


export default {
  name: "ProductManagementView",
  components: {
    IndexView, PageTitle, InputText, AddButton,
    PencilSquareIcon, TrashIcon
  },
  computed: {
    ...mapGetters('products', ['allProducts']),
    // ...mapGetters('categories', ['allCategories']),
  },
  methods: {
    ...mapActions('products', ['deleteProductById']),
    formatRupiah,
    editProduct(product) {
      this.$router.push({ name: 'EditProduct', params: {id: product.id} });
    },
    async deleteProduct(product) {
      if (confirm(`Yakin ingin menghapus produk ${product.name}?`)) {
        try {
          await this.deleteProductById(product.id);
          alert('Produk berhasil dihapus');
          this.$store.dispatch('products/fetchProducts');
        } catch (error) {
          console.error('Gagal menghapus produk', error);
          alert('Gagal menghapus produk');
        }
      }
    }
  },
  created(){
    this.$store.dispatch('products/fetchProducts');
    // this.$store.dispatch('categories/allCategories');
  }
}
</script>