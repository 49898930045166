<template>
    <div>
        <label for="password" class="font-medium" :class="label_class">{{ label }}</label>
        <input
            :name="name"
            :placeholder="placeholder"
            :value="modelValue"
            :id="id"
            @input="$emit('update:modelValue', $event.target.value)" 
            type="password"
            class="block w-full flex-1 border rounded-md px-3 py-2 text-color-1 placeholder:text-gray-400 focus:outline-none focus:ring focus:ring-color-5 sm:text-sm sm:leading-6">
    </div>
</template>

<script>
export default {
    name: 'InputPassword',
    props: {
        label: String,
        label_class: String,
        id: String,
        name: String,
        placeholder: String,
        modelValue: String,
    },
    emits: ['update:modelValue'],
}
</script>