<template>
  <section class="bg-color-1 h-full">
    <div class="lg:px-20 sm:px-5 py-16 h-full">
        <div class="mb-20">
            <h1 class="text-3xl font-bold text-center text-white">Welcome to Erista POS</h1>
        </div>
        <div class="flex justify-center">
            <div class="grid grid-cols-2 gap-10 justify lg:w-3/4 sm:w-full">
                <div class="flex justify-center">
                    <UserCard class="h-64 lg:w-3/4 sm:w-full" />
                </div>
                <div class="flex justify-between flex-col">
                    <span id="errorMessage" v-show="errorMessage" class="text-red-700 w-full bg-red-300 font-medium px-3 py-2 rounded-md flex items-center">
                        <ExclamationTriangleIcon class="w-5 h-5 me-2"/>
                        {{ errorMessage }}
                    </span>
                    <div><InputText id="user-email" v-model="email" type="email" label="Email" label_class="text-white" placeholder="Email anda" input_class="w-full"/></div>
                    <InputPassword id="user-password" v-model="password" label="Password" label_class="mb-2 text-white block text-start" placeholder="Password anda"/>
                    <div class="flex">
                        <SubmitButton id="submit" @click="login" button_class="bg-color-4 px-5 py-2 text-sm font-semibold text-color-1 hover:bg-color-3 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-color-3" button_name="Login"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import UserCard from '@/components/UserCard.vue';
import InputText from '@/components/form/InputText.vue';
import InputPassword from '@/components/form/InputPassword.vue';
import SubmitButton from '@/components/form/SubmitButton.vue';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid';
import axios from 'axios';

export default {
    name: 'AuthView',
    components: {
        UserCard, InputPassword, SubmitButton, InputText,
        ExclamationTriangleIcon
    },
    data() {
        return {
            email: '',
            password: '',
            errorMessage: '',
        }
    },
    methods: {
        async login() {
            try {
                const response = await axios.post('https://erpprojectdevapi.erista.xyz/api/v1/auth', {
                    email: this.email,
                    password: this.password
                });

                if(response.data.status == 'success') {
                    const token = response.data.data.token;

                    localStorage.setItem('auth_token', token);
                    this.$router.push('/dashboard');
                } else {
                    this.errorMessage = response.data.message;
                }
            } catch(error) {
                this.errorMessage = 'Email atau Password salah';
            }
        }
    }
}
</script>