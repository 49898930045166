<template>
  <ModalCategory
    v-if="modalShowCategory"
    @closeModal="closeModal"
    :category="selectedCategory"/>
  <IndexView />
  <div
    id="overlay"
    class="fixed inset-0 bg-black bg-opacity-30 z-30"
    :class="{ 'hidden': !isBlur }"
  ></div>
  <section class="p-6">
    <PageTitle title="Kategori" />
    <div class="bg-white w-1/2 lg:w-1/2 md:w-3/4 rounded-lg py-6 px-5 shadow-lg">
      <div id="table-header" class="mb-5 flex justify-between">
        <AddButton to_url="#" button_text="+ Tambah Kategori" button_class="text-base py-2 font-medium" @click="showModal"/>
      </div>
      <table class="table-auto w-full">
        <thead class="bg-color-1">
          <tr>
            <th class="p-3 border-b text-color-4 text-left">No</th>
            <th class="p-3 border-b text-color-4 text-left">Nama Kategori</th>
            <th class="p-3 border-b text-color-4 text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="allCategories.length == 0">
            <td colspan="3" class="p-3 text-center italic text-gray-400">Belum ada data</td>
          </tr>
          <tr v-else v-for="(category, index) in allCategories" :key="category.id" :class="{'bg-blue-50' : index % 2 == 1}" class="hover:bg-blue-100">
            <td class="p-3">{{ index + 1 }}</td>
            <td class="p-3">{{ category.name }}</td>
            <td class="p-3 text-gray-500 flex">
              <button :id="'edit-data-' + category.id" @click="editCategory(category)" class="border border-gray-400 hover:border-green-700 hover:bg-green-400 flex justify-center items-center mx-1 p-2 rounded-md group">
                <PencilSquareIcon class="h-4 w-4 group-hover:text-green-700"/>
              </button>
              <button :id="'delete-data-' + category.id" @click="deleteCategory(category)" class="border border-gray-400 hover:border-red-500 hover:bg-red-400 flex justify-center items-center mx-1 p-2 rounded-md group">
                <TrashIcon class="h-4 w-4 group-hover:text-red-700"/>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import IndexView from '@/views/IndexView.vue';
import PageTitle from '@/components/PageTitle.vue';
import AddButton from '@/components/buttons/AddButton.vue';
import ModalCategory from '@/components/modals/ModalCategory.vue';
import { PencilSquareIcon, TrashIcon } from '@heroicons/vue/24/solid';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "CategoryView",
  components: {
    IndexView, PageTitle, AddButton, ModalCategory,
    PencilSquareIcon, TrashIcon
  },
  data() {
    return {
      modalShowCategory: false,
      isBlur: false,
      selectedCategory: null
    }
  },
  computed: {
    ...mapGetters('categories', ['allCategories'])
  },
  created() {
    this.$store.dispatch('categories/fetchCategories');
  },
  methods: {
    ...mapActions('categories', ['deleteCategoryById']),
    showModal() {
      this.modalShowCategory = true;
      this.isBlur = true;
      this.selectedCategory = null;
    },
    closeModal() {
      setTimeout(() => {
        this.modalShowCategory = false;
        this.isBlur = false;
        this.$store.dispatch('categories/fetchCategories');
      }, 200);
    },
    editCategory(category) {
      this.selectedCategory = category;
      this.modalShowCategory = true;
      this.isBlur = true;
    },  
    async deleteCategory(category) {
      if (confirm(`Yakin ingin menghapus kategori ${category.name}?`)) {
        try {
          await this.deleteCategoryById(category.id);
          alert('Kategori berhasil dihapus');
          this.$store.dispatch('categories/fetchCategories');
        }
        catch (error) {
          console.error('Gagal menghapus kategori', error);
          alert('Gagal menghapus kategori');
        }
      }
    }
  },  
}
</script>