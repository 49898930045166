<template>
  <!-- <button :id="button_id" :class="button_class" class="hover:bg-color-1 hover:text-color-4 rounded-lg py-1 px-4 cursor-pointer border border-color-1">{{ button_text }}</button> -->
  <RouterLink :to="to_url" id="add-data" :class="button_class" class="hover:bg-color-1 hover:text-color-4 flex items-center rounded-lg px-4 cursor-pointer border border-color-1">
    {{ button_text }}
  </RouterLink>
</template>

<script>
export default {
  name: "AddButton",
  props: {
    id: String,
    button_class: String,
    button_text: String,
    to_url: String
  }
}
</script>