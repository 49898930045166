
<style>
select .option {
  color: #A0AEC0 !important;
}
</style>

<template>
  <IndexView/>
  <section class="p-6">
    <BackButton class="mb-5" to_url="/kelola-produk" />
    <PageTitle :title="isEdit ? 'Edit Produk' : 'Tambah Produk'"/>
    <div class="w-3/4 bg-white p-6 rounded-md shadow-md mb-6">

      <!-- INFOMASI PRODUK -->
      <h2 class="font-bold text-xl mb-8">Informasi Produk</h2>
      <div class="grid grid-cols-12 gap-4 mb-5">
        <div class="col-span-2 flex flex-col">
          <h5 class="font-medium">Poto Produk</h5>
        </div>
        <div class="col-span-10 flex flex-col">
          <div class="flex">
            <span class="bg-gray-100 w-28 h-28 border border-gray-300 justify-center flex items-center text-gray-400 mx-2 text-xs text-center">upload poto</span>
            <span class="bg-gray-100 w-28 h-28 border border-gray-300 justify-center flex items-center text-gray-400 mx-2 text-xs text-center">upload poto</span>
            <span class="bg-gray-100 w-28 h-28 border border-gray-300 justify-center flex items-center text-gray-400 mx-2 text-xs text-center">upload poto</span>
            <span class="bg-gray-100 w-28 h-28 border border-gray-300 justify-center flex items-center text-gray-400 mx-2 text-xs text-center">upload poto</span>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 mb-5">
        <div class="col-span-2 flex flex-col">
          <h5 class="font-medium">Nama Produk</h5>
        </div>
        <div class="col-span-10 flex flex-col">
          <InputText id="product-name" v-model="product.name" input_class="w-full mb-4" label_class="mb-1 block text-start" type="text" placeholder="Nama produk"/>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 mb-5">
        <div class="col-span-2 flex flex-col">
          <h5 class="font-medium">Kode Produk</h5>
        </div>
        <div class="col-span-10 flex flex-col">
          <InputText id="product-code" v-model="product.code" input_class="w-full mb-4" label_class="mb-1 block text-start" type="text" placeholder="Kode produk"/>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 mb-5">
        <div class="col-span-2 flex flex-col">
          <h5 class="font-medium">Barcode</h5>
        </div>
        <div class="col-span-10 flex flex-col">
          <InputText id="product-barcode" v-model="product.barcode" input_class="w-full mb-4" label_class="mb-1 block text-start" type="text"/>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 mb-5">
        <div class="col-span-2 flex flex-col">
          <h5 class="font-medium">Kategori</h5>
        </div>
        <div class="col-span-6 flex flex-col">
          <select id="product-category-id" v-model="product.category_id" class="w-full border border-gray-400 rounded-md px-3 py-2 text-gray-500 text-sm">
            <option value="">Pilih kategori</option>
            <option v-for="category in allCategories" :key="category.id" :value="category.id">{{ category.name }}</option>
          </select>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 mb-5">
        <div class="col-span-2 flex flex-col">
          <h5 class="font-medium">Deskripsi</h5>
        </div>
        <div class="col-span-10 flex flex-col">
          <TextArea id="desc" v-model="product.desc" />
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 mb-5 items-center">
        <div class="col-span-2 flex flex-col">
          <h5 class="font-medium">Is Service</h5>
        </div>
        <div class="col-span-1 flex flex-col items-start">
          <input v-model="product.is_service" type="checkbox" :checked="product.is_service == true">
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 mb-5 items-center">
        <div class="col-span-2 flex flex-col">
          <h5 class="font-medium">Produk Untuk Diual</h5>
        </div>
        <div class="col-span-1 flex flex-col items-start">
          <input v-model="product.saleable" type="checkbox" :checked="product.saleable == true">
        </div>
      </div>
    </div>

    <!-- SPESIFIKASI -->
    <div class="w-3/4 bg-white p-6 rounded-md shadow-md mb-6">
      <h2 class="font-bold text-xl mb-8">Spesifikasi</h2>
      <div class="grid grid-cols-12 gap-10 mb-5">
        <div class="col-span-6">
          <div class="flex mb-5">
            <h5 class="font-medium me-4">Berat (gr)</h5>
            <InputText id="product-weight" v-model="product.weight" placeholder="Cth: 1000" input_class="w-20 mb-4" label_class="mb-1 block text-start" type="text"/>
          </div>
          <div class="flex mb-5">
            <h5 class="font-medium me-4">Type</h5>
            <InputText id="product-type" v-model="product.type" input_class="w-20 mb-4" label_class="mb-1 block text-start" type="text"/>
          </div>
          <div class="flex mb-5">
            <h5 class="font-medium me-4">Satuan</h5>
            <InputText id="product-height" v-model="product.unit" placeholder="Cth: pcs/kg/kardus" input_class="w-20 mb-4" label_class="mb-1 block text-start" type="text"/>
          </div>
        </div>
        <div class="col-span-6">
          <div class="flex mb-5">
            <h5 class="font-medium me-4">Tinggi (mm)</h5>
            <InputText id="product-height" v-model="product.height" input_class="w-20 mb-4" label_class="mb-1 block text-start" type="text"/>
          </div>
          <div class="flex mb-5">
            <h5 class="font-medium me-4">Lebar (mm)</h5>
            <InputText id="product-width" v-model="product.width" input_class="w-20 mb-4" label_class="mb-1 block text-start" type="text"/>
          </div>
        </div>
      </div>
    </div>

    <!-- INFORMASI PENJUALAN -->
    <div class="w-3/4 bg-white p-6 rounded-md shadow-md mb-6">
      <h2 class="font-bold text-xl mb-8">Informasi Penjualan</h2>
      <div class="grid grid-cols-12 gap-4 mb-5">
        <div class="col-span-2">
          <h5 class="font-medium">Harga Beli</h5>
        </div>
        <div class="col-span-5">
          <InputText id="product-purchase-price" v-model="product.purchase_price" input_class="w-full mb-4" label_class="mb-1 block text-start" type="text"/>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 mb-5">
        <div class="col-span-2">
          <h5 class="font-medium">Harga Jual</h5>
        </div>
        <div class="col-span-5">
          <InputText id="product-sale-price" v-model="product.sale_price" input_class="w-full mb-4" label_class="mb-1 block text-start" type="text"/>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 mb-5">
        <div class="col-span-2">
          <h5 class="font-medium">Stok</h5>
        </div>
        <div class="col-span-3">
          <InputText id="product-qty" v-model="product.qty" input_class="w-full mb-4" label_class="mb-1 block text-start" type="text"/>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 mb-5">
        <div class="col-span-2">
          <h5 class="font-medium">Diskon</h5>
        </div>
        <div class="col-span-3">
          <InputText id="product-discount" v-model="product.discount" input_class="w-full mb-4" label_class="mb-1 block text-start" type="text"/>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 mb-5">
        <div class="col-span-2">
          <h5 class="font-medium">Pajak</h5>
        </div>
        <div class="col-span-3">
          <InputText id="product-tax" v-model="product.tax" input_class="w-full mb-4" label_class="mb-1 block text-start" type="text"/>
        </div>
      </div>
    </div>

    <div class="w-3/4 bg-white p-6 rounded-md shadow-md mb-6 flex justify-center sticky">
      <BackButton to_url="/kelola-produk" class="mx-2"/>
      <SubmitButton id="submit" @click="submitProduct" button_name="Simpan" button_class="mx-2 rounded-md bg-color-1 px-6 py-2 text-base font-semibold text-color-4 shadow-sm hover:bg-color-2 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-color-3 block"/>
    </div>
  </section>
</template>

<script>
import IndexView from '@/views/IndexView.vue';
import PageTitle from '@/components/PageTitle.vue';
import BackButton from '@/components/buttons/BackButton.vue';
import InputText from '@/components/form/InputText.vue';
import TextArea from '@/components/form/TextArea.vue';
import SubmitButton from '@/components/form/SubmitButton.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "AddEditProduct",
  props: ['id'],
  components: {
    IndexView, PageTitle, BackButton, InputText, SubmitButton, TextArea
  },
  data() {
    return {
      product: {
        id: null,
        name: '',
        code: '',
        barcode: '',
        category_id: '',
        desc: '',
        weight: '',
        height: '',
        width: '',
        type: '',
        unit: '',
        purchase_price: '',
        sale_price: '',
        qty: '',
        discount: '',
        tax: '',
        saleable: '',
        is_service: false
      },
      isEdit: false
    }
  },
  methods: {
    ...mapActions('products', ['createProduct', 'fetchProductById', 'updateProduct']),
    
    async submitProduct () {
      this.product.sale_price = this.product.sale_price - this.product.discount - this.product.tax;

      if(this.isEdit) {
        try {
          await this.updateProduct(this.product);
          alert('Data produk berhasil diubah!');
          this.$router.push('/kelola-produk');
        }
        catch (error) {
          alert('Failed to update employee', error);
        }
      }
      else {
        try {
          await this.createProduct(this.product);
          alert('Produk berhasil ditambah');
          this.$router.push('/kelola-produk');
        }
        catch (error) {
          console.error('Failed to create product', error);
          alert('Failed to create product', error);
        }
      }
    }
  },
  computed: {
    ...mapGetters('categories', ['allCategories']),
  },
  async created(){
    this.$store.dispatch('categories/fetchCategories');

    const productId = this.id;
    if (productId) {
      this.isEdit = true;

      try {
        const response = await this.fetchProductById(productId);
        const productData = response.data;

        this.product = {
          id: productData.id,
          name: productData.name,
          code: productData.code,
          barcode: productData.barcode,
          category_id: productData.category_id,
          desc: productData.desc,
          weight: productData.weight,
          height: productData.height,
          width: productData.width,
          type: productData.type,
          purchase_price: productData.purchase_price,
          sale_price: productData.sale_price,
          qty: productData.qty,
          discount: productData.discount,
          tax: productData.tax,
        };
      }
      catch (error) {
        console.error('Failed to fetch employee', error);
      }
    }
  }
}
</script>